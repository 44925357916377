import React, { useContext, useState } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { SlugContext } from '../context/SlugContext';
import MusicContext from '../context/MusicContext';
import Postagem from '../screens/Postagem';

interface NotificacaoType {
  id_notificacao: number;
  tipo_notificacao: string;
  mensagem: string;
  id_conversa?: number;
  id_banda?: number;
  id_usuario?: number;
  id_inscritor?: number;
  tipo_inscritor?: string;
  id_banda_envia?: number;
  id_usu_solicita?: number;
  id_usu_solicita_amizade?: number;
  id_musica?: number;
  id_postagem?: number;
  status: 'lida' | 'não lida';  // Garantindo que isso seja consistente com Notificacao
}

interface ModalNotificacoesProps {
  isOpen: boolean;
  onClose: () => void;
  notificacoes: NotificacaoType[];
  onNotificacaoClick: (idConversa: number) => void;
}

const ModalNotificacoes: React.FC<ModalNotificacoesProps> = ({ isOpen, onClose, notificacoes, onNotificacaoClick }) => {
  const navigate = useNavigate();
  const { dispatch } = useContext(SlugContext);
  const musicContext = useContext(MusicContext);
  const { state: authState } = useContext(AuthContext);
  const [selectedPostId, setSelectedPostId] = useState<number | null>(null);
  const [postagemModalOpen, setPostagemModalOpen] = useState(false);

  if (!isOpen) return null;

  const handleNotificationClick = async (notificacao: NotificacaoType) => {
    await marcarNotificacaoComoLida(notificacao.id_notificacao);

    switch (notificacao.tipo_notificacao) {
      case 'mensagem':
        if (notificacao.id_conversa) onNotificacaoClick(notificacao.id_conversa);
        break;
      case 'inscricao':
        handleInscriptionNotificationClick(notificacao);
        break;
      case 'amigos':
        handleFriendshipNotificationClick(notificacao);
        break;
      case 'curtida_musica':
        handleMusicLikeNotificationClick(notificacao);
        break;
      case 'curtida':
        if (notificacao.id_postagem) openPostModal(notificacao.id_postagem);
        break;
      case 'familia':
        handleFamilyNotificationClick(notificacao);
        break;
      case 'convite':
        handleInvitationNotificationClick(notificacao);
        break;
      case 'aceite_familia':
        handleAcceptNotificationClick(notificacao);
        break;
      default:
        console.log('Tipo de notificação não tratado:', notificacao.tipo_notificacao);
    }
  };

  const handleInscriptionNotificationClick = async (notificacao: NotificacaoType) => {
    if (notificacao.tipo_notificacao === 'inscricao') {
      try {
        const url = notificacao.tipo_inscritor === 'usuario'
          ? `https://tribhus.shop:5000/users/get-slug/${notificacao.id_inscritor}`
          : `https://tribhus.shop:5000/bands/get-slug/${notificacao.id_inscritor}`;

        const response = await axios.get(url, { headers: { Authorization: `Bearer ${authState.token}` } });
        const slug = response.data.slug;

        if (slug) {
          onClose();
          dispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
          navigate(notificacao.tipo_inscritor === 'usuario' ? '/TelaUsuarioPerfil' : '/TelaBandaPerfil', { replace: true });
        }
      } catch (error) {
        console.error('Erro ao buscar slug:', error);
      }
    }
  };

  const handleInvitationNotificationClick = async (notificacao: NotificacaoType) => {
    if (notificacao.tipo_notificacao === 'convite' && notificacao.id_banda_envia) {
      try {
        const url = `https://tribhus.shop:5000/bands/get-slug/${notificacao.id_banda_envia}`;
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${authState.token}` } });
        const slug = response.data.slug;

        if (slug) {
          dispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
          onClose();
          navigate('/TelaBandaPerfil', { replace: true });
        }
      } catch (error) {
        console.error('Erro ao buscar slug da banda:', error);
      }
    }
  };

  const handleAcceptNotificationClick = async (notificacao: NotificacaoType) => {
    if (notificacao.tipo_notificacao === 'aceite_familia' && notificacao.id_usu_solicita) {
      try {
        const url = `https://tribhus.shop:5000/users/get-slug/${notificacao.id_usu_solicita}`;
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${authState.token}` } });
        const slug = response.data.slug;

        if (slug) {
          dispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
          onClose();
          navigate('/TelaUsuarioPerfil', { replace: true });
        }
      } catch (error) {
        console.error('Erro ao buscar slug do usuário:', error);
      }
    }
  };

  const handleFamilyNotificationClick = async (notificacao: NotificacaoType) => {
    if (notificacao.tipo_notificacao === 'familia' && notificacao.id_usu_solicita) {
      try {
        const profileUrl = `https://tribhus.shop:5000/users/get-slug/${notificacao.id_usu_solicita}`;
        const response = await axios.get(profileUrl, { headers: { Authorization: `Bearer ${authState.token}` } });
        const slug = response.data.slug;

        if (slug) {
          onClose();
          dispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
          navigate('/TelaUsuarioPerfil', { replace: true });
        }
      } catch (error) {
        console.error('Erro ao buscar slug:', error);
      }
    }
  };

  const handleFriendshipNotificationClick = async (notificacao: NotificacaoType) => {
    if (notificacao.tipo_notificacao === 'amigos' && notificacao.id_usu_solicita_amizade) {
      try {
        const url = `https://tribhus.shop:5000/users/get-slug/${notificacao.id_usu_solicita_amizade}`;
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${authState.token}` } });
        const slug = response.data.slug;

        if (slug) {
          dispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
          onClose();
          navigate('/TelaUsuarioPerfil');
        }
      } catch (error) {
        console.error('Erro ao buscar slug:', error);
      }
    }
  };

  const handleMusicLikeNotificationClick = async (notificacao: NotificacaoType) => {
    if (notificacao.tipo_notificacao === 'curtida_musica' && notificacao.id_musica) {
      try {
        const url = `https://tribhus.shop:5000/bands/details/${notificacao.id_musica}`;
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${authState.token}` } });
        const musicaCurtida = response.data;

        if (musicaCurtida && musicContext) {
          musicContext.setPlaylist([musicaCurtida]);
          musicContext.selectTrack(musicaCurtida);
          onClose();
          navigate('/AudioPlayer');
        }
      } catch (error) {
        console.error('Erro ao buscar detalhes da música:', error);
      }
    }
  };

  const marcarNotificacaoComoLida = async (idNotificacao: number) => {
    try {
      const url = `https://tribhus.shop:5000/notificacoes/markAsRead`;
      const body = { id_notificacao: idNotificacao };
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authState.token}`,
      };
      await axios.put(url, body, { headers });
    } catch (error) {
      console.error('Erro ao marcar notificação como lida:', error);
    }
  };

  const openPostModal = (postId: number) => {
    setSelectedPostId(postId);
    setPostagemModalOpen(true);
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>Fechar</button>
        <h2 style={styles.title}>Notificações</h2>
        <div style={styles.notificationList}>
          {notificacoes.map((notificacao, index) => {
            let notificacaoTexto = '';
            switch (notificacao.tipo_notificacao) {
              case 'mensagem':
                notificacaoTexto = notificacao.mensagem;
                break;
              case 'inscricao':
                notificacaoTexto = 'Você tem uma nova inscrição!';
                break;
              case 'amigos':
                notificacaoTexto = 'Você tem uma nova solicitação de amizade.';
                break;
              case 'curtida_musica':
                notificacaoTexto = 'Sua música tem uma nova curtida!';
                break;
              case 'curtida':
                notificacaoTexto = 'Sua postagem tem uma nova curtida!';
                break;
              case 'familia':
                notificacaoTexto = 'Sua Banda tem um novo familiar.';
                break;
              case 'convite':
                notificacaoTexto = 'Você foi convidado para fazer parte da família de uma banda.';
                break;
              case 'aceite_familia':
                notificacaoTexto = 'Seu convite de família foi aceito, veja seu novo familiar.';
                break;
            }
  
            return (
              <div 
                key={index}
                style={{
                  ...styles.notification,
                  ...(notificacao.status === 'lida' ? styles.notificationRead : {})
                }}
                onClick={() => handleNotificationClick(notificacao)}
              >
                <span style={styles.notificationText}>{notificacaoTexto}</span>
                <button style={styles.actionButton}>Ver</button>
              </div>
            );
          })}
        </div>
      </div>
      {selectedPostId !== null && (
        <Postagem
          isOpen={postagemModalOpen}
          onClose={() => {
            setPostagemModalOpen(false);
            setSelectedPostId(null);
          }}
          postId={selectedPostId}
        />
      )}
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  } as React.CSSProperties,
  modal: {
    backgroundColor: '#202020',
    padding: '20px',
    width: '60%',
    maxHeight: '80%',
    overflowY: 'auto',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  } as React.CSSProperties,
  closeButton: {
    alignSelf: 'flex-end',
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
  } as React.CSSProperties,
  title: {
    color: 'white',
    fontSize: '24px',
    margin: '0 0 20px 0',
  } as React.CSSProperties,
  notificationList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  } as React.CSSProperties,
  notification: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#333',
    borderRadius: '5px',
    cursor: 'pointer',
  } as React.CSSProperties,
  notificationRead: {
    opacity: 0.6,
  } as React.CSSProperties,
  notificationText: {
    color: 'white',
    flex: 1,
  } as React.CSSProperties,
  actionButton: {
    backgroundColor: '#914100',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
    cursor: 'pointer',
  } as React.CSSProperties,
};

export default ModalNotificacoes;