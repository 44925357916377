import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { AuthContext } from '../context/AuthContext';
import { SlugContext } from '../context/SlugContext';
import MusicContext from '../context/MusicContext';
import { useNavigate } from 'react-router-dom';
import LikeButton from '../Components/LikeButton';
import DeletePostComment from '../Components/DeletePostComment';
import MenuButton from '../Services/MenuButton'; 
import YouTube from 'react-youtube';
import TabBar from '../Components/TabBar'; 
import Postagem from './Postagem';
import CreatePost from './CreatePost';
import AddPost from '../assets/add_circle_active.png';
import MiniPlayer from '../Components/MiniPlayer';
import BotaoDenuncias from '../Components/BotaoDenuncias';
import commentIcon from '../assets/com.png'; 
import noImage from '../assets/no_image.png';

interface Postagem {
  id_postagem: number;
  nome: string;
  sobrenome?: string;
  url_capa?: string;
  image_url?: string;
  video_url?: string;
  conteudo: string;
  data_hora: string;
  likes_count?: number;
  user_or_banda_liked_id?: number;
  comentarios_ativados?: boolean;
  id_usuario?: number;
  id_banda?: number;
  slug: string;
}

interface Band {
  generos: string[];
  url_capa_banda?: string;
  url_icone_banda?: string;
  nome_banda?: string;
  descricao?: string;
  id_banda?: number;
  slug?: string;
}

interface User {
  generos: string[];
  url_capa_usuario?: string;
  url_icone_usuario?: string;
  nome?: string;
  sobrenome?: string;
  descricao?: string;
  id_usuario?: number;
  cidade?: string;
  estado?: string;
  slug?: string;
}

interface Comment {
  id_comentario: number;
  texto: string;
  data_hora: string;
  nome: string;
  sobrenome?: string; 
  url_perfil?: string;
  id_usuario?: number; 
  id_banda?: number;
  slug: string; 
}

type ProfileData = Band | User;


// Função para extrair o ID do vídeo do URL do YouTube
const extractYouTubeVideoId = (url: string): string | null => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
};

const YouTubeVideo = React.forwardRef<{ pause: () => void; play: () => void }, { videoId: string }>(({ videoId }, ref) => {
  const playerRef = useRef<any>(null);

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
    },
  };

  const onReady = (event: any) => {
    playerRef.current = event.target;
  };

  React.useImperativeHandle(ref, () => ({
    pause: () => {
      if (playerRef.current) {
        playerRef.current.pauseVideo();
      }
    },
    play: () => {
      if (playerRef.current) {
        playerRef.current.playVideo();
      }
    }
  }));

  return <YouTube videoId={videoId} opts={opts} onReady={onReady} />;
});

const Palco: React.FC = () => {
  const [posts, setPosts] = useState<Postagem[]>([]);
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  const [postComments, setPostComments] = useState<{ [key: number]: Comment[] }>({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState<number | null>(null);
  const [selectedCommentId, setSelectedCommentId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const { state: authState } = useContext(AuthContext);
  const { id_usuario, id_banda, token } = authState;
  const { dispatch: slugDispatch } = useContext(SlugContext);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const playerRefs = useRef<{ [key: number]: { pause: () => void } }>({});
  const [isCreatePostOpen, setIsCreatePostOpen] = useState(false); 
  const musicContext = useContext(MusicContext);
  const currentTrack = musicContext?.state.track;

  useEffect(() => {
    console.log("AuthState:", authState);
    if (token) {
      fetchProfileData();
      fetchPosts();
    } else {
      console.log("Token não disponível no AuthContext");
    }
  }, [token, id_usuario, id_banda]);

  const fetchProfileData = async () => {
    if (!token) {
      console.log("Token não disponível para a requisição");
      return;
    }

    try {
      let response;
      if (id_usuario) {
        console.log(`Buscando dados do usuário com ID: ${id_usuario}`);
        response = await axios.get<User>(`https://tribhus.shop:5000/users/${id_usuario}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } else if (id_banda) {
        console.log(`Buscando dados da banda com ID: ${id_banda}`);
        response = await axios.get<Band>(`https://tribhus.shop:5000/bands/banda/${id_banda}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } else {
        console.log("Nem id_usuario nem id_banda estão disponíveis");
        return;
      }

      if (response && response.data) {
       setProfileData(response.data);
      } else {
        console.log("Nenhum dado recebido do backend");
      }
    } catch (error) {
      console.error('Erro ao buscar dados do perfil:', error);
      if (axios.isAxiosError(error)) {
        console.error('Detalhes do erro:', error.response?.data);
      }
    }
  };

  const fetchPosts = async () => {
    if (!token) {
      console.log("Token não disponível para buscar posts");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get<Postagem[]>('https://tribhus.shop:5000/palco', {
        params: {
          id_banda,
          id_usuario,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      
      console.log('Dados de posts recebidos do backend:', response.data);
      setPosts(response.data);

      // Carregar comentários para cada post
      response.data.forEach(post => fetchCommentsForPost(post.id_postagem));
    } catch (error) {
      console.error('Erro ao buscar posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const isBand = (profile: ProfileData | null): profile is Band => {
    return profile !== null && 'nome_banda' in profile;
  };
    
  const fetchCommentsForPost = async (postId: number) => {
    if (!token) {
      console.log('Nenhum token disponível - usuário provavelmente deslogado');
      return;
    }

    try {
      const response = await axios.get(`https://tribhus.shop:5000/palco/${postId}/comments`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPostComments(prevState => ({
        ...prevState,
        [postId]: response.data
      }));
    } catch (error) {
      console.error('Error fetching comments for post:', postId, error);
    }
  };

  const isOwner = (item: Postagem | Comment): boolean => {
    if (authState.id_usuario && item.id_usuario) {
      return authState.id_usuario === item.id_usuario;
    }
    if (authState.id_banda && item.id_banda) {
      return authState.id_banda === item.id_banda;
    }
    return false;
  };

  const handleDeleteClick = (postId: number, commentId?: number) => {
    console.log(`Iniciando exclusão - PostID: ${postId}, CommentID: ${commentId}`);
    setSelectedPostId(postId);
    setSelectedCommentId(commentId || null);
    setDeleteModalVisible(true);
  };

  const handleDeleteComplete = () => {
    console.log("Exclusão completada, recarregando dados");
    setDeleteModalVisible(false);
    fetchPosts(); // Recarregar os posts após a exclusão
  };

  const navigateToProfile = (slug: string, id_usuario?: number, id_banda?: number) => {
    slugDispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
    
    if (id_usuario) {
      navigate('/TelaUsuarioPerfil');
    } else if (id_banda) {
      navigate('/TelaBandaPerfil');
    }
  };

  const handlePostClick = (postId: number) => {
    // Pause all videos
    Object.values(playerRefs.current).forEach(player => player.pause());
    setSelectedPostId(postId);
    setModalOpen(true);
  };

  const handleOpenCreatePost = () => {
    setIsCreatePostOpen(true);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };

  return (
    <div style={styles.container}>
      {!token && <p>Aguardando autenticação...</p>}
      {token && (
        <>
          {/* Cabeçalho com imagem de capa e ícone de perfil */}
          <div style={styles.headerContainer}>
            {/* Imagem de capa */}
            <div
              style={{
                ...styles.coverImage,
                backgroundImage: `url(${isBand(profileData) ? profileData?.url_capa_banda : profileData?.url_capa_usuario})`,
              }}
            />

            {/* Ícone de perfil */}
            <div
              style={{
                ...styles.profileIcon,
                backgroundImage: `url(${isBand(profileData) ? profileData?.url_icone_banda : profileData?.url_icone_usuario})`,
              }}
            />
          </div>
          <div style={styles.mainContent}>
            {/* Coluna do TabBar e MiniPlayer */}
            <div style={styles.leftColumn}>
              <TabBar currentScreen="Palco" currentSlug={profileData?.slug} />
              {currentTrack && (
                <div style={styles.miniPlayerContainer}>
                  <MiniPlayer />
                </div>
              )}
            </div>
            {/* Coluna do Feed */}
            <div style={styles.feedColumn}>
              {/* Botão de criar nova postagem */}
              <div style={styles.createPostButtonContainer}>
                <button onClick={handleOpenCreatePost} style={styles.createPostButton}>
                  <img src={AddPost} alt="Criar Nova Postagem" style={styles.createPostIcon} />
                </button>
              </div>

              {loading ? (
                <div>Carregando...</div>
              ) : (
                posts.map((post) => {
                  const videoId = post.video_url ? extractYouTubeVideoId(post.video_url) : null;

                  return (
                    <React.Fragment key={post.id_postagem}>
                      <div
                        key={post.id_postagem}
                        style={styles.postContainer}
                        onClick={() => handlePostClick(post.id_postagem)} // Evento de clique no contêiner
                      >
<div style={styles.postHeader}>
  <div
    style={styles.authorInfo}
    onClick={() => navigateToProfile(post.slug, post.id_usuario, post.id_banda)}
  >
    <img
      src={post.url_capa && post.url_capa.trim() !== "https://sate.live:9000/tribhusmidias/" ? post.url_capa : noImage} // Verifica se tem URL, caso contrário, usa a imagem padrão
      alt="Autor da postagem"
      style={{
        ...styles.authorIcon,
        backgroundColor: '#f0f0f0', // Estilo da imagem ou do contêiner
        height: '50px', // Define altura e largura para a imagem
        width: '50px',
        borderRadius: '50%', // Torna circular
      }}
    />
    <h4 style={styles.postTitle}>
      {post.nome} {post.sobrenome}
    </h4>
  </div>
  <div style={styles.postHeaderButtons} onClick={(e) => e.stopPropagation()}>
          {isOwner(post) ? (
            <MenuButton onClick={() => handleDeleteClick(post.id_postagem)} />
          ) : (
        <BotaoDenuncias
      itemId={post.id_postagem}
      tipoItem="postagem"
      onDenunciaEnviada={() => console.log('Denúncia de postagem enviada com sucesso!')}
      />
          )}
  </div>
</div>
                        {post.image_url && (
                          <img src={post.image_url} alt="Imagem da postagem" style={styles.postImage} />
                        )}
                        <p style={styles.postContent}>{post.conteudo}</p>
                        {videoId && (
                          <div
                            style={styles.videoContainer}
                            onClick={() => handlePostClick(post.id_postagem)} // Pausar vídeo ao clicar
                          >
                            <YouTubeVideo
                              videoId={videoId}
                              ref={(ref) => {
                                if (ref) playerRefs.current[post.id_postagem] = ref;
                              }}
                            />
                          </div>
                        )}
                        <p style={styles.postInfo}>{formatDate(post.data_hora)}</p>
                      </div>

                      {/* Container para ações do post */}
                      <div style={styles.postActionsContainer}>
                        <LikeButton
                          postId={post.id_postagem}
                          initialLikes={post.likes_count ?? 0}
                          user_or_banda_liked_id={post.user_or_banda_liked_id ?? null}
                          liked={Boolean(post.user_or_banda_liked_id)}
                          contentType="postagem"
                          slug={post.slug}
                        />
                        {/* Adicionando o ícone de comentários */}
                        {post.comentarios_ativados && (
                          <img
                            src={commentIcon}
                            alt="Comentários"
                            style={styles.commentIcon}
                            onClick={() => handlePostClick(post.id_postagem)} // Navegar para a tela de postagem ao clicar no ícone
                          />
                        )}
                      </div>

                      {post.comentarios_ativados && (
                        <div style={styles.commentContainer}>
                          <p style={styles.postInfo}>Comentários:</p>
                          {postComments[post.id_postagem] &&
                            postComments[post.id_postagem].slice(0, 2).map((comment: Comment) => (
                              <div key={comment.id_comentario} style={styles.commentContainer}>
                                <div style={styles.authorContainer}>
                                {isOwner(comment) && (
                <MenuButton
                  onClick={() => handleDeleteClick(post.id_postagem, comment.id_comentario)}
                />
              )}
                                  <div
                                    style={styles.commentAuthorInfo}
                                    onClick={() =>
                                      navigateToProfile(
                                        comment.slug,
                                        comment.id_usuario,
                                        comment.id_banda
                                      )
                                    }
                                  >
                                    <img
                                      src={comment.url_perfil}
                                      alt="Perfil"
                                      style={styles.commentImage}
                                    />
                                    <p style={styles.commentAuthor}>
                                      {`${comment.nome} ${comment.sobrenome || ''}`}
                                    </p>
                                  </div>
                                </div>

                                {/* Envolver o texto do comentário e o botão de denúncia em um novo contêiner */}
                                <div style={styles.commentTextContainer}>
                                  <p style={styles.commentText}>{comment.texto}</p>
                                  {/* Botão de denúncia alinhado à direita do texto */}
                                  {!isOwner(comment) && (
                                    <BotaoDenuncias
                                       itemId={comment.id_comentario}
                                        tipoItem="comentario"
                                        onDenunciaEnviada={() =>
                                        console.log('Denúncia de comentário enviada com sucesso!')
                                      }
                                     />
                                  )}
                                </div>
                                <p style={styles.commentDate}>
                                  {formatDate(comment.data_hora)}
                                </p>
                              </div>
                            ))}
                          {postComments[post.id_postagem] &&
                            postComments[post.id_postagem].length > 2 && (
                              <button style={styles.moreCommentsButton}>
                                <span style={styles.moreCommentsText}>mais comentários...</span>
                              </button>
                            )}
                        </div>
                      )}
                    </React.Fragment>
                  );
                })
              )}
            </div>
          </div>
        </>
      )}
      <DeletePostComment
        isVisible={deleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
        postId={selectedPostId}
        commentId={selectedCommentId}
        onPostDeleted={handleDeleteComplete}
        onCommentDeleted={handleDeleteComplete}
      />
      {selectedPostId && (
        <Postagem
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          postId={selectedPostId}
        />
      )}
      <CreatePost isOpen={isCreatePostOpen} onClose={() => setIsCreatePostOpen(false)} />
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  pageContainer: {
    backgroundColor: '#151922',
    minHeight: '100vh',
    color: 'white',
  },
  container: {
    backgroundColor: '#151922',
    minHeight: '100vh',
    color: 'white',
    padding: '20px',
  },
  headerContainer: {
    position: 'relative',
    width: '100%',
    height: '200px',
    marginBottom: '70px',
  },
  miniPlayerContainer: {
    marginTop: '60px',
  },
  mainContent: {
    display: 'flex',
    padding: '0 20px',
  },
  leftColumn: {
    width: '250px', // Ajuste conforme necessário
    marginRight: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  feedColumn: {
    flex: 1,
    overflowY: 'auto',
  },
  tabBarColumn: {
    width: '20%', // Ajuste conforme necessário
    paddingRight: '20px',
  },
  createPostButton: {
    position: 'fixed',
    bottom: '40px',
    right: '40px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    zIndex: 1000,
  },
  createPostIcon: {
    width: '50px',
    height: '50px',
  },
  coverImage: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '8px',
  },
  videoContainer: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  profileIcon: {
    position: 'absolute',
    bottom: '-80px',
    left: '880px',
    width: '180px',
    height: '180px',
    borderRadius: '50%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: '4px solid #151922',
    boxShadow: '0 2px 4px rgba(255,255,255,0.2)',
    marginBottom: '30px',
  },
  postContainer: {
    marginBottom: '20px',
    padding: '15px',
    borderRadius: '8px',
    backgroundColor: '#111111',
    display: 'flex', // Para usar flexbox
    flexDirection: 'column', // Para alinhar os elementos verticalmente
    alignItems: 'center', // Para centralizar horizontalmente
  },
  postHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    width: '100%',
  },
  postHeaderButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px', // Espaçamento entre os botões
  },    
  authorIcon: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '10px',
    objectFit: 'cover',
  },
  authorInfo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  commentAuthorInfo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  commentTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Alinha o texto à esquerda e o botão à direita
    width: '100%',
    marginBottom: '5px',
  },
  commentText: {
    color: 'white',
    marginBottom: 0, // Remove a margem inferior para alinhamento adequado
    marginRight: '10px', // Espaço entre o texto e o botão
    flex: 1, // Permite que o texto ocupe o espaço disponível
  },
  postTitle: {
    fontSize: '18px',
    marginBottom: '10px',
  },
  postImage: {
    maxWidth: '100%', // Mantém a imagem dentro da largura do contêiner
    maxHeight: '300px', // Define uma altura máxima para as imagens
    height: 'auto',
    width: 'auto', // Mantém a proporção da imagem
    marginBottom: '10px',
    borderRadius: '4px',
    objectFit: 'contain', // Garante que a imagem seja redimensionada para caber dentro dos limites
  },
  postVideo: {
    maxWidth: '100%',
    marginBottom: '10px',
    borderRadius: '4px',
  },
  postContent: {
    fontSize: '16px',
    marginBottom: '10px',
    textAlign: 'center', // Centraliza o texto da postagem
    width: '100%', // Garante que o texto ocupe toda a largura do contêiner
  },
  postInfo: {
    fontSize: '14px',
    color: '#aaa',
    marginBottom: '5px',
  },
  commentContainer: {
    marginTop: 10,
    padding: 10,
    backgroundColor: 'transparent', // Alterado de '#f0f0f0' para 'transparent'
    borderRadius: 5,
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)' // Opcional: linha de separação sutil
  },
  authorContainer: {
    display: 'flex',
    alignItems: 'center', // Garante que a imagem e o texto estejam alinhados verticalmente
    marginBottom: 5
  },
  commentImage: {
    width: '30px', // Tamanho reduzido para a imagem do autor
    height: '30px',
    borderRadius: '50%', // Torna a imagem redonda
    marginRight: '10px', // Espaço entre a imagem e o nome do autor
    objectFit: 'cover' // Garante que a imagem cubra todo o espaço sem deformação
  },
  commentAuthor: {
    fontWeight: 'bold',
    marginBottom: 5,
    color: 'white' // Assegura que o texto seja visível com fundo escuro
  },
  commentDate: {
    fontSize: 12,
    color: '#aaa',
    marginBottom: 5
  },
  moreCommentsButton: {
    background: 'none',
    border: 'none',
    color: 'white',
    cursor: 'pointer'
  },
  moreCommentsText: {
    textDecoration: 'underline'
  },
  postActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Distribui o espaço entre os itens
    width: '100%', // Garante que o contêiner ocupe toda a largura
  },
  
  denunciaButton: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '1px',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#ff6347', // Mantém a cor do botão de denúncia
   },
};

export default Palco;