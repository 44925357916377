import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Palcoimg from '../assets/palco.png';
import Adicionarimg from '../assets/add_circle.png';
import Bandasimg from '../assets/banda.png';
import Homeimg from '../assets/home.png';
import { AuthContext } from '../context/AuthContext';
import { SlugContext } from '../context/SlugContext';
import CreatePost from '../screens/CreatePost';

interface TabBarProps {
    currentScreen: string;
    currentSlug?: string;
  }
  
  const TabBar: React.FC<TabBarProps> = ({ currentScreen, currentSlug }) => {
      const navigate = useNavigate();
      const { state: authState } = useContext(AuthContext);
      const { dispatch } = useContext(SlugContext);
      const [isCreatePostOpen, setIsCreatePostOpen] = useState(false);
  
      useEffect(() => {
       }, [currentScreen, currentSlug]);
  
       const isHome = currentSlug ? authState.slug === currentSlug : true;
  
       const handleHomePress = () => {
        const slugToUse = authState.slug;
    
        if (authState.id_usuario) {
            if (slugToUse !== null) {
                dispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slugToUse });
            }
            navigate('/TelaUsuarioPerfil');
        } else if (authState.id_banda) {
            if (slugToUse !== null) {
                dispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slugToUse });
            }
            navigate('/TelaBandaPerfil');
        } else {
            navigate('/SegundaTela');
        }
    };
  
      const handleOpenCreatePost = () => {
          setIsCreatePostOpen(true);
      };
  
      const handleCloseCreatePost = () => {
          setIsCreatePostOpen(false);
      };
  
      const buttons = [
          { text: 'Palco', icon: Palcoimg, route: '/Palco' },
          { text: 'Adicionar', icon: Adicionarimg, onClick: handleOpenCreatePost },
          { text: 'Bandas', icon: Bandasimg, route: '/Bandas' },
          { text: 'Home', icon: Homeimg, onClick: handleHomePress },
      ];
  
      return (
          <>
              <div style={styles.tabBarContainer}>
                  {buttons.map((button, index) => {
      const isActive = button.text.toLowerCase() === currentScreen.toLowerCase() && (button.text !== 'Home' || isHome);
      return (
                          <div key={index} style={styles.tabItem}>
                              <button 
                                  onClick={button.onClick || (() => navigate(button.route))}
                                  style={styles.button}
                              >
                                  <img 
                                      src={button.icon} 
                                      alt={button.text} 
                                      style={styles.icon}
                                  />
                                  <span style={isActive ? styles.activeButton : styles.inactiveButton}>
                                      {button.text}
                                  </span>
                              </button>
                          </div>
                      );
                  })}
              </div>
              <CreatePost 
                  isOpen={isCreatePostOpen} 
                  onClose={handleCloseCreatePost}
       //           onPostCreated={handlePostCreated}
              />
          </>
      );
  };

  const styles: { [key: string]: React.CSSProperties } = {
    tabBarContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        padding: '5px',
        backgroundColor: '#2D2D2D',
        borderRadius: '10px', // Cantos arredondados
        minHeight: '30vh', // Faz o contêiner ocupar a altura total da tela
        overflow: 'auto', // Permite que o conteúdo role dentro do contêiner
    },
    tabItem: {},
    button: {
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        borderRadius: '5px',
        padding: '10px 15px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        width: '100%',
        backgroundColor: 'transparent',
    },
    activeButton: {
        color: '#FF6600',
        fontSize: '18px',
        fontWeight: 'bold',
    },
    inactiveButton: {
        color: '#FFFFFF',
        fontSize: '18px',
        fontWeight: 'bold',
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '10px',
    },
};

export default TabBar;
