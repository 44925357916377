import React, { useCallback, useState, useEffect, useRef } from 'react';
import axios from '../Services/axiosInterceptor'; 
import Modal from './ModalAlbum';

interface Album {
  id_album: number;
  nome_album: string;
  ano_lancamento: number;
  url_capa_album: string;
  id_banda: number;
}

interface AlbumEstanteProps {
  idUsuario: number;
  token: string;
}

const AlbumEstante: React.FC<AlbumEstanteProps> = ({ idUsuario, token }) => {
  const [albuns, setAlbuns] = useState<Album[]>([]);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [selectedAlbum, setSelectedAlbum] = useState<Album | null>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const handleAlbumClick = (album: Album) => {
    setSelectedAlbum(album);
  };

  const fetchAlbuns = useCallback(async () => {
    if (!token) {
      console.error('No token available - user likely logged out');
      return;
    }
    try {
      const response = await axios.get(`https://tribhus.shop:5000/homeuser/${idUsuario}/albums`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setAlbuns(response.data);
      console.log('dados recebidos da estante:', response.data);
    } catch (error) {
      console.error('Erro ao buscar álbuns:', error);
    }
  }, [idUsuario, token]);

  useEffect(() => {
    fetchAlbuns();
  }, [fetchAlbuns]);

  const handleScroll = (direction: 'left' | 'right') => {
    if (listRef.current) {
      const scrollAmount = 200;
      if (direction === 'left') {
        listRef.current.scrollLeft -= scrollAmount;
      } else {
        listRef.current.scrollLeft += scrollAmount;
      }
    }
  };

  const handleScrollVisibility = () => {
    if (listRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    const list = listRef.current;
    if (list) {
      list.addEventListener('scroll', handleScrollVisibility);
      handleScrollVisibility(); // Checar visibilidade inicial
    }
    return () => {
      if (list) {
        list.removeEventListener('scroll', handleScrollVisibility);
      }
    };
  }, []);

  return (
    <div style={styles.wrapper}>
      <h2 style={styles.sectionTitle}>Estante</h2>
      <div style={styles.container}>
        {showLeftArrow && (
          <div style={{ ...styles.arrow, ...styles.leftArrow }} onClick={() => handleScroll("left")}>
            &#9664;
          </div>
        )}
        <div style={styles.list} ref={listRef}>
          {albuns.map((album) => (
            <div key={album.id_album} style={styles.card} onClick={() => handleAlbumClick(album)}>
              <div style={styles.imageWrapper}>
                <img src={album.url_capa_album} alt={album.nome_album} style={styles.image} />
              </div>
              <p style={styles.title}>{album.nome_album}</p>
              <p style={styles.year}>{album.ano_lancamento}</p>
            </div>
          ))}
        </div>
        {showRightArrow && (
          <div style={{ ...styles.arrow, ...styles.rightArrow }} onClick={() => handleScroll("right")}>
            &#9654;
          </div>
        )}
      </div>
      {selectedAlbum && (
        <Modal 
          isOpen={true} 
          album={selectedAlbum} 
          onClose={() => setSelectedAlbum(null)}
        />
      )}
    </div>
  );
};

const styles = {
  wrapper: {
    padding: '0 20px',
  } as React.CSSProperties,
  container: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    position: 'relative',
    maxWidth: '100%',
    margin: '0 auto',
    alignItems: 'center',
  } as React.CSSProperties,
  sectionTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#FF6600',
    margin: '40px 0 20px 0',
  } as React.CSSProperties,
  list: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    scrollSnapType: 'x mandatory',
    scrollBehavior: 'smooth',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    padding: '0 20px',
  } as React.CSSProperties,
  card: {
    flex: '0 0 auto',
    scrollSnapAlign: 'start',
    margin: '0 10px',
    padding: 10,
    borderRadius: 10,
    position: 'relative',
    width: '200px',
    backgroundColor: '#202020',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  } as React.CSSProperties,
  imageWrapper: {
    width: '100%',
    height: '200px',
    overflow: 'hidden',
    borderRadius: '10px',
  } as React.CSSProperties,
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  } as React.CSSProperties,
  title: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
    margin: '10px 0',
    textAlign: 'center',
  } as React.CSSProperties,
  year: {
    color: 'white',
    fontSize: 12,
  } as React.CSSProperties,
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    zIndex: 10,
    transition: 'opacity 0.3s',
    opacity: 0.7,
  } as React.CSSProperties,
  leftArrow: {
    left: '10px',
  } as React.CSSProperties,
  rightArrow: {
    right: '10px',
  } as React.CSSProperties,
};

export default AlbumEstante;