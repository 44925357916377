import React, { createContext, useReducer, useEffect, useContext } from 'react';
import { NavigateFunction } from 'react-router-dom';
import axios from '../Services/axiosInterceptor';
import { setupInterceptors } from '../Services/axiosInterceptor';

// Definição do estado
interface State {
  token: string | null;
  refreshToken: string | null;
  slug: string | null;
  authSlug: string | null;
  id_banda: number | null;
  id_usuario: number | null;
  auth_id: number | null;
  isLoggingOut: boolean;
  sessionId: string | null;
  isAuthChecked: boolean;
}

const initialState: State = {
  token: null,
  refreshToken: null,
  slug: null,
  authSlug: null,
  id_banda: null,
  id_usuario: null,
  auth_id: null,
  isLoggingOut: false,
  sessionId: null,
  isAuthChecked: false,
};

// Ações
type Action = 
  | { type: 'SET_TOKEN'; token: string }
  | { type: 'SET_REFRESH_TOKEN'; refreshToken: string }
  | { type: 'SET_SLUG_PERFIL'; slugPerfil: string }
  | { type: 'SET_AUTH_SLUG'; authSlug: string }
  | { type: 'SET_BANDA_ID'; id_banda: number }
  | { type: 'SET_USUARIO_ID'; id_usuario: number }
  | { type: 'SET_AUTH_ID'; auth_id: number }
  | { type: 'SET_IS_LOGGING_OUT'; isLoggingOut: boolean }
  | { type: 'SET_SESSION_ID'; sessionId: string }
  | { type: 'SET_IS_AUTH_CHECKED'; isAuthChecked: boolean }
  | { type: 'RESET_AUTH' }
  | { type: 'SET_SLUG'; slug: string };

// Reducer
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_TOKEN':
      return { ...state, token: action.token };
    case 'SET_REFRESH_TOKEN':
      return { ...state, refreshToken: action.refreshToken };
    case 'SET_SLUG_PERFIL':
      return { ...state, slug: action.slugPerfil };
    case 'SET_SLUG':
      return { ...state, slug: action.slug };
    case 'SET_AUTH_SLUG':
      return { ...state, authSlug: action.authSlug };
    case 'SET_BANDA_ID':
      return { ...state, id_banda: action.id_banda };
    case 'SET_USUARIO_ID':
      return { ...state, id_usuario: action.id_usuario };
    case 'SET_AUTH_ID':
      return { ...state, auth_id: action.auth_id };
    case 'SET_IS_LOGGING_OUT':
      return { ...state, isLoggingOut: action.isLoggingOut };
    case 'SET_SESSION_ID':
      return { ...state, sessionId: action.sessionId };
    case 'SET_IS_AUTH_CHECKED':
      return { ...state, isAuthChecked: action.isAuthChecked };
    case 'RESET_AUTH':
      return { ...initialState, isAuthChecked: true };
    default:
      return state;
  }
};

// Contexto
interface AuthContextType {
  state: State;
  dispatch: React.Dispatch<Action>;
  login: (email: string, senha: string) => Promise<boolean>;
  logout: () => Promise<void>;
  updateToken: (accessToken: string, refreshToken: string) => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  state: initialState,
  dispatch: () => null,
  login: async () => false,
  logout: async () => {},
  updateToken: async () => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
  navigate: NavigateFunction;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, navigate }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateToken = async (accessToken: string, refreshToken: string) => {
    dispatch({ type: 'SET_TOKEN', token: accessToken });
    dispatch({ type: 'SET_REFRESH_TOKEN', refreshToken: refreshToken });
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  };  

  const logout = async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      const sessionId = localStorage.getItem('sessionId');
      if (refreshToken && sessionId) {
        await axios.post('/users/logout', { refreshToken, sessionId });
      }
    } catch (error) {
      console.error('Erro ao fazer logout no servidor:', error);
    } finally {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('sessionId');
      localStorage.removeItem('slug');
      localStorage.removeItem('auth_id');
      dispatch({ type: 'RESET_AUTH' });
      navigate('/login');
    }
  };

  useEffect(() => {
    const checkInitialAuth = async () => {
      const storedToken = localStorage.getItem('accessToken');
      const storedRefreshToken = localStorage.getItem('refreshToken');
      const storedSlug = localStorage.getItem('slug');
      const storedAuthId = localStorage.getItem('auth_id');
      const storedIdBanda = localStorage.getItem('id_banda');
      const storedIdUsuario = localStorage.getItem('id_usuario');

      if (storedToken && storedRefreshToken) {
        try {
          await updateToken(storedToken, storedRefreshToken);
          if (storedSlug) {
            dispatch({ type: 'SET_SLUG', slug: storedSlug });
          }
          if (storedAuthId) {
            dispatch({ type: 'SET_AUTH_ID', auth_id: parseInt(storedAuthId) });
          }
          if (storedIdBanda) {
            dispatch({ type: 'SET_BANDA_ID', id_banda: parseInt(storedIdBanda) });
          } else if (storedIdUsuario) {
            dispatch({ type: 'SET_USUARIO_ID', id_usuario: parseInt(storedIdUsuario) });
          }
          // Aqui você pode adicionar uma chamada ao backend para obter dados atualizados do usuário, se necessário
        } catch (error) {
          console.error("Erro ao verificar autenticação inicial:", error);
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          localStorage.removeItem('slug');
          localStorage.removeItem('auth_id');
          localStorage.removeItem('id_banda');
          localStorage.removeItem('id_usuario');
        }
      }
      dispatch({ type: 'SET_IS_AUTH_CHECKED', isAuthChecked: true });
    };

    checkInitialAuth();
    setupInterceptors(updateToken);
  }, []);

  const login = async (email: string, senha: string): Promise<boolean> => {
    try {
      const response = await axios.post('/users/login', {
        email,
        senha,
        platform: 'web'
      });

      const { accessToken, refreshToken, id_banda, id_usuario, slug, role, auth_id, sessionId } = response.data;

      if (accessToken && refreshToken) {
        await updateToken(accessToken, refreshToken);
        localStorage.setItem('sessionId', sessionId);
        localStorage.setItem('slug', slug);
        localStorage.setItem('auth_id', auth_id.toString()); // Armazena auth_id no localStorage
    
        dispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
        dispatch({ type: 'SET_AUTH_SLUG', authSlug: slug });
        dispatch({ type: 'SET_AUTH_ID', auth_id });
        dispatch({ type: 'SET_SESSION_ID', sessionId });

        if (role === 'banda') {
          dispatch({ type: 'SET_BANDA_ID', id_banda });
          localStorage.setItem('id_banda', id_banda.toString()); // Armazena id_banda
        } else {
          dispatch({ type: 'SET_USUARIO_ID', id_usuario });
          localStorage.setItem('id_usuario', id_usuario.toString()); // Armazena id_usuario
        }

        return true;
      } else {
        console.error('Tokens não recebidos no login');
        return false;
      }
    } catch (error) {
      console.error('Erro durante o login:', error);
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ state, dispatch, login, logout, updateToken }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook useAuth para uso em componentes
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

//temos tanto a propriedade slug quanto authSlug no estado. A diferença entre as duas pode estar 
//gerando confusão.
//Aqui estão as diferenças que notei:
//slug:
//Usado principalmente para armazenar o slug do perfil atual que está sendo exibido. 
//Isso faz sentido quando você está navegando no perfil de uma banda ou de outro usuário.
//authSlug:
//Especificamente o slug do usuário autenticado. Ele representa o slug do usuário ou banda que 
//está logado.
//posteriormente precisamos remover um dos dois, pois precisamos apenas de um slug, de quem está logado.