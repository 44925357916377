import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor';

interface ProtectedLayoutProps {
  children: React.ReactNode;
}

interface CustomJwtPayload {
  exp: number;
  auth_id: number;
  specific_id: number;
  role: 'usuario' | 'banda';
}

const ProtectedLayout: React.FC<ProtectedLayoutProps> = ({ children }) => {
  const { state, dispatch: authDispatch, updateToken } = useContext(AuthContext);
  const [isChecking, setIsChecking] = useState(true);
  const updateTokenRef = useRef(updateToken);

  useEffect(() => {
    updateTokenRef.current = updateToken;
  }, [updateToken]);

  const updateAuthContext = useCallback((token: string) => {
    const decodedToken: CustomJwtPayload = jwtDecode(token);
    if (decodedToken.role === 'banda') {
      authDispatch({ type: 'SET_BANDA_ID', id_banda: decodedToken.specific_id });
    } else if (decodedToken.role === 'usuario') {
      authDispatch({ type: 'SET_USUARIO_ID', id_usuario: decodedToken.specific_id });
    }
    authDispatch({ type: 'SET_TOKEN', token });
  }, [authDispatch]);

  const clearTokensAndRedirect = useCallback(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('sessionId');
    authDispatch({ type: 'RESET_AUTH' });
    setIsChecking(false); // Adicionar isso para sair da verificação de autenticação
  }, [authDispatch]);

// Função para verificar a validade do refresh token no backend
const verifyTokenInBackend = async (refreshToken: string) => {
  try {
    const response = await axios.post(
      '/delete/verify-tokenreact',
      { refreshToken },  // Enviar o token no corpo da requisição
    );

    if (response.data.valid) {
      return true;  // Token válido
    } else {
      return false; // Token inválido
    }
  } catch (error) {
    console.error('Erro ao verificar a validade do token no backend:', error);
    return false;
  }
};
  
  useEffect(() => {
    const verifySessionAndCheckTokens = async () => {
      const refreshToken = localStorage.getItem('refreshToken');
      const accessToken = localStorage.getItem('accessToken');
  
      // Log para verificar os tokens
      console.log("Refresh token encontrado:", refreshToken);
      console.log("Access token encontrado:", accessToken);
  
      if (refreshToken) {
        console.log("Chamando verifyTokenInBackend...");
        const isValidInBackend = await verifyTokenInBackend(refreshToken);
  
        if (!isValidInBackend) {
          console.log("Sessão inválida no backend, redirecionando...");
          clearTokensAndRedirect();
          return; // Parar o fluxo aqui se o refresh token não for válido
        }
  
        // Agora que o refresh token é válido no backend, seguimos para verificar o access token
        if (accessToken) {
          try {
            const decodedToken: CustomJwtPayload = jwtDecode(accessToken);
            const currentTimestamp = Math.floor(Date.now() / 1000);
  
            if (decodedToken.exp < currentTimestamp) {
              console.log("Access token expirado, tentando atualizar...");
              // Tentar atualizar o access token
              const response = await axios.post('/users/refresh', {
                refreshToken,
                sessionId: localStorage.getItem('sessionId'),
                platform: 'web',
              });
  
              if (response.data.accessToken) {
                console.log("Tokens atualizados com sucesso!");
                await updateTokenRef.current(response.data.accessToken, response.data.refreshToken);
                updateAuthContext(response.data.accessToken);
              } else {
                clearTokensAndRedirect();
              }
            } else {
              console.log("Access token ainda é válido.");
              updateAuthContext(accessToken);
            }
          } catch (error) {
            console.error('Erro ao decodificar o access token:', error);
            clearTokensAndRedirect();
          }
        } else {
          console.log("Nenhum access token encontrado, redirecionando...");
          clearTokensAndRedirect();
        }
      } else {
        console.log("Nenhum refresh token encontrado, redirecionando...");
        clearTokensAndRedirect();
      }
  
      setIsChecking(false); // Parar de checar depois que o processo é concluído
    };
  
    verifySessionAndCheckTokens();
  }, [updateAuthContext, clearTokensAndRedirect]); 

  if (isChecking) {
    return <div>Verificando autenticação...</div>;
  }

  if (!state.token) {
    return <Navigate to="/PrimeiraTela" />;
  }

  return <>{children}</>;
};

export default ProtectedLayout;
