import React, { useState, useContext, useEffect, useRef } from 'react';
import MusicContext from '../context/MusicContext';
import MusicLikeButton from '../Components/MusicLikeButton';
import AddToPlaylistModal from '../Components/AddToPlaylistModal';
import BotaoDenuncias from '../Components/BotaoDenuncias';
import { useNavigate } from 'react-router-dom';
import { Volume2, VolumeX } from 'lucide-react';

const AudioPlayer: React.FC = () => {
    const [showModal, setShowModal] = useState(false);
    const musicContext = useContext(MusicContext);
    if (!musicContext) {
        throw new Error("MusicContext not found");
    }
    const { state, selectTrack, togglePlay, seekTo, setVolume, toggleMute } = musicContext;
    const { track: currentTrack, playlist, isPlaying, currentTime, duration, volume, isMuted } = state;
    const navigate = useNavigate();
    const onClose = () => navigate(-1);
    const audioRef = useRef<HTMLAudioElement>(null);

    const handleTogglePlay = () => {
        togglePlay();
    };

    const formatTime = (timeInSeconds: number): string => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = Math.floor(timeInSeconds % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newVolume = parseFloat(e.target.value);
        setVolume(newVolume);
    };

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume;
            audioRef.current.muted = isMuted;
        }
    }, [volume, isMuted]);

    useEffect(() => {
        console.log('AudioPlayer - currentTime:', currentTime);
        console.log('AudioPlayer - duration:', duration);
    }, [currentTime, duration]);
    
    const handleProgressBarClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (duration > 0) {
            const progressBarWidth = e.currentTarget.offsetWidth;
            const clickX = e.nativeEvent.offsetX;
            const newProgressPercentage = (clickX / progressBarWidth);
            const newTime = newProgressPercentage * duration;
            console.log('Clicking progress bar - newTime:', newTime, 'duration:', duration);
            seekTo(newTime);
        }
    };

    const currentTrackIndex = playlist.findIndex(track => track.id_musica === currentTrack?.id_musica);

    const nextTrack = () => {
        if (currentTrackIndex < playlist.length - 1) {
            selectTrack(playlist[currentTrackIndex + 1]);
        }
    };

    const prevTrack = () => {
        if (currentTrackIndex > 0) {
            selectTrack(playlist[currentTrackIndex - 1]);
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.topBar}>
                <button onClick={onClose} style={styles.backButton}>
                    <img
                        src={require('../assets/voltar.png')}
                        alt="Voltar"
                        style={styles.backIcon}
                    />
                </button>
                {currentTrack && (
                    <div style={{
                        color: 'white',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        flex: 1
                    }}>
                        {currentTrack.nome_banda}
                    </div>
                )}
            </div>
            <div style={styles.contentContainer}>
                <div style={styles.leftContainer}>
                    {currentTrack ? (
                        <>
                            <div style={styles.imageContainer}>
                                <img src={currentTrack.url_imagem} alt="Album Cover" style={styles.image} />
                            </div>
                            <div style={styles.volumeControl}>
                    <button onClick={toggleMute} style={styles.muteButton}>
                        {isMuted ? <VolumeX size={20} /> : <Volume2 size={20} />}
                    </button>
                    <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        value={isMuted ? 0 : volume}
                        onChange={handleVolumeChange}
                        style={styles.volumeSlider}
                    />
                </div>
                            <div style={styles.trackInfoContainer}>
                              <MusicLikeButton trackId={currentTrack.id_musica} />
                                <BotaoDenuncias
                                    itemId={currentTrack.id_musica}
                                    tipoItem="musica"
                                    onDenunciaEnviada={() => {
                                     }}
                                />
                                <span style={styles.trackInfoText}>
                                    {currentTrack.nome_musica}
                                </span>
                            </div>
                         </>
                        ) : (
                        <p>Selecione uma música para reproduzir</p>
                    )}
                </div>

                <div style={styles.rightContainer}>
                    {playlist.map((track, index) => (
                        <div
                            key={track.id_musica}
                            style={{ cursor: 'pointer' }}
                            onClick={() => selectTrack(track)}
                        >
                            <span
                                style={{
                                    color: index === currentTrackIndex ? '#914100' : 'white'
                                }}
                            >
                                {track.nome_musica}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
            <div style={styles.buttonContainer}>
                <button style={styles.button} onClick={prevTrack}>Previous</button>
                <button style={styles.button} onClick={handleTogglePlay}>
                    {isPlaying ? 'Pause' : 'Play'}
                </button>
                <button style={styles.button} onClick={nextTrack}>Next</button>

                {currentTrack && (
                    <button
                        style={{ ...styles.button, marginLeft: '10px' }}
                        onClick={() => setShowModal(true)}
                    >
                        +
                    </button>
                )}
            </div>

            {showModal && currentTrack && (
                <AddToPlaylistModal
                    currentTrackId={currentTrack.id_musica}
                    currentTrackName={currentTrack.nome_musica}
                    closeModal={() => setShowModal(false)}
                />
            )}

<div 
            style={styles.progressBarContainer} 
            onClick={handleProgressBarClick}
        >
            <div 
                style={{
                    ...styles.progressBar,
                    width: duration > 0 ? `${(currentTime / duration) * 100}%` : '0%'
                }}
            ></div>
        </div>
        <div style={styles.timeContainer}>
            <span>{formatTime(currentTime)}</span>
            <span>{formatTime(duration)}</span>
        </div>
      </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#151922',
        height: '100vh',
        width: '100vw',
        position: 'relative', // Adicione esta linha para que o botão 'absolute' se posicione dentro deste contêiner
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        maxWidth: '800px',
        width: '100%',
        marginBottom: '20px'
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '380px',
        margin: '20px 0'
    },
    imageContainer: {
        width: '300px',
        height: '300px',
        marginBottom: '20px',
        background: '#333',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    backButton: {
        position: 'absolute', // Posiciona o botão fora do fluxo normal do layout
        top: '40px', // Ajuste a posição vertical conforme necessário
        left: '40px', // Ajuste a posição horizontal conforme necessário
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        padding: '0',
    },
    backIcon: {
        width: '24px',
        height: '24px',
    },
    image: {
        width: '400px',
        height: '400px',
        maxWidth: '340px',
        maxHeight: '340px'
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#ff6347',
        color: '#fff',
        border: 'none',
        borderRadius: '10px',
        cursor: 'pointer',
    },
    progressBarContainer: {
        width: '90%',
        height: '10px',
        backgroundColor: '#333',
        cursor: 'pointer'
    },
    progressBar: {
        height: '100%',
        backgroundColor: '#ff6347',
    },
    timeContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '90%',
        fontSize: '14px',
        color: '#fff',
        marginTop: '10px'
    },
    leftContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#333',
        padding: '20px'
    },
    rightContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#333',
        padding: '20px',
        overflowY: 'auto'
    },
    trackInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '10px'
    },
    trackInfoText: {
        color: '#fff',
        marginLeft: '10px',
        fontSize: '18px'
    },
    volumeControl: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '20px',
    },
    muteButton: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        color: '#fff',
        padding: '5px',
    },
    volumeSlider: {
        width: '100px',
        margin: '0 10px',
    },
};

export default AudioPlayer;
