import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterContext } from '../context/RegisterContext';
import { useAuth } from '../context/AuthContext';
import backgroundImg from '../assets/fundologin.png';
import logoImg from '../assets/logotipo.png';
import DocumentModal from '../documents/DocumentModal';

type DocumentType = 'sobre' | 'termos-banda' | 'termos-usuario' | 'privacidade';

const PrimeiraTela: React.FC = () => {
  const navigate = useNavigate();
  const { state: registerState, dispatch: registerDispatch } = useContext(RegisterContext);
  const [isLoading, setIsLoading] = useState(false);
  const { state, login } = useAuth();
  const [modalContent, setModalContent] = useState<DocumentType | null>(null);

  useEffect(() => {
    if (state.token) {
      navigate('/Palco');
    }
  }, [state.token, navigate]);

  useEffect(() => {
    registerDispatch({ type: 'SET_FORM', field: 'email', value: '' });
    registerDispatch({ type: 'SET_FORM', field: 'senha', value: '' });
    registerDispatch({ type: 'SET_ERROR', error: '' });
  }, [registerDispatch]);

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const loginSuccess = await login(registerState.email, registerState.senha);
      
      if (loginSuccess) {
        console.log('Login bem-sucedido');
        navigate('/Palco');
      } else {
        console.log('Login falhou');
        registerDispatch({ type: 'SET_ERROR', error: 'Login falhou. Verifique suas credenciais.' });
      }
    } catch (error) {
      console.error('Erro durante o login:', error);
      registerDispatch({ type: 'SET_ERROR', error: 'Ocorreu um erro durante o login. Tente novamente.' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignUp = () => {
    navigate('/TerceiraTela');
  };

  const handleForgotPassword = () => {
    navigate('/PasswordResetRequestScreen');
  };

  const openModal = (content: DocumentType) => {
    setModalContent(content);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.imageContainer}>
        <div style={styles.logoContainer}>
          <img src={logoImg} alt="Logo" style={styles.logo} />
          <div style={styles.title}>Tribhus: Onde fãs e bandas se encontram!</div>
          <div style={styles.subtitle}>Aqui você vai encontrar os principais lançamentos do rock nacional, underground e independente.</div>
        </div>
        <div style={styles.formContainer}>
          <div style={styles.titleLogin}>Faça seu login ou cadastre-se</div>
          <input
            style={styles.input}
            onChange={e => registerDispatch({ type: 'SET_FORM', field: 'email', value: e.target.value })}
            value={registerState.email}
            placeholder="Usuário"
          />
          <input
            style={styles.input}
            onChange={e => registerDispatch({ type: 'SET_FORM', field: 'senha', value: e.target.value })}
            value={registerState.senha}
            placeholder="Senha"
            type="password"
          />
          <button style={styles.forgotPasswordButton} onClick={handleForgotPassword}>
            <span style={styles.forgotPasswordText}>Esqueceu a senha?</span>
          </button>
          <button
            style={styles.button}
            onClick={handleLogin}
            disabled={isLoading}
          >
            <span style={styles.buttonText}>{isLoading ? 'Carregando...' : 'LOGIN'}</span>
          </button>
          <button style={styles.signupButton} onClick={handleSignUp}>
            <span style={styles.signupText}>Não tem login? Faça seu cadastro!</span>
          </button>
          {registerState.error && <div style={styles.errorText}>{registerState.error}</div>}
          <div style={styles.documentLinks}>
            <button style={styles.documentButton} onClick={() => openModal('sobre')}>Sobre</button>
            <button style={styles.documentButton} onClick={() => openModal('termos-banda')}>Termos de Uso da Banda</button>
            <button style={styles.documentButton} onClick={() => openModal('termos-usuario')}>Termos de Uso do Usuário</button>
            <button style={styles.documentButton} onClick={() => openModal('privacidade')}>Políticas de Privacidade</button>
          </div>
        </div>
        {modalContent && <DocumentModal content={modalContent} onClose={closeModal} />}
      </div>
      
      <div style={styles.footerContainer}>
        <div style={styles.footerText}>
          TRIBHUS DISTRIBUICAO DE CONTEUDO DIGITAL LTDA - CNPJ: 36.380.391/0001-64
        </div>
        <div style={styles.footerText}>
          Telefone: 19 9 9950-9709 | Email: admin@tribhus.com.br
        </div>
      </div>
    </div>
  );
};
  
  const styles: { [key: string]: React.CSSProperties } = {
    pageContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    imageContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundImage: `url(${backgroundImg})`,
      backgroundSize: 'cover',
      width: '100%',
      backgroundColor: '#151922',
      padding: '0 5%',
    },
    logoContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2rem 0',
    },
    logo: {
      width: '400px',
      height: 'auto',
    },
    title: {
      fontSize: '3rem',
      color: '#FF6600',
      fontWeight: 'bold',
      textAlign: 'left',
      marginBottom: '0.5rem',
    },
    subtitle: {
      fontSize: '1rem',
      color: '#fff',
      textAlign: 'center',
      marginBottom: '2rem',
    },
    formContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2rem',
    },
    titleLogin: {
      fontSize: '1.5rem',
      color: '#fff',
      textAlign: 'center',
      marginBottom: '2rem',
    },
    input: {
      height: '40px',
      width: '50%',
      borderColor: '#914100',
      borderWidth: '1px',
      borderRadius: '10px',
      marginBottom: '1rem',
      paddingLeft: '0.5rem',
      color: '#454545',
    },
    button: {
      backgroundColor: '#914100',
      color: '#fff1e5',
      padding: '1rem 0.5rem',
      borderRadius: '20px',
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '2rem',
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
    },
    buttonText: {
      color: 'inherit',
      fontSize: '1.2rem',
    },
    signupText: {
      color: '#fff',
      flex: 1,
      textAlign: 'left',
      fontSize: '1rem',
    },
    senhaText: {
      color: '#FF6600',
      flex: 1,
      textAlign: 'right',
      paddingLeft: '30%', 
      marginBottom: 20,
    },
    signupButton: {
      background: 'none',
      border: 'none',
      color: '#fff',
      cursor: 'pointer',
      padding: '10px',
      textAlign: 'center',
      marginBottom: '60px',
    },
    errorText: {
      color: 'red',
      marginBottom: '1rem',
    },
    documentLinks: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
      marginTop: '2rem',
    },
    documentButton: {
      background: 'none',
      border: 'none',
      color: '#FF6600',
      cursor: 'pointer',
      padding: '0.5rem',
      margin: '0 0.5rem',
      fontSize: '0.9rem',
      textDecoration: 'underline',
    },
    forgotPasswordButton: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      padding: '5px',
      marginBottom: '10px',
      alignSelf: 'flex-end',
      marginRight: '25%',
    },
    forgotPasswordText: {
      color: '#FF6600',
      fontSize: '0.9rem',
      textDecoration: 'underline',
    },
    footerContainer: {
      backgroundColor: '#151922',
      padding: '10px',
      textAlign: 'center',
      color: '#fff',
      fontSize: '0.8rem',
    },
    footerText: {
      color: '#bbb',
      fontSize: '0.8rem',
      marginBottom: '5px',
    },
  };
  
  export default PrimeiraTela;  