import React from 'react';

interface SocialMediaInputProps {
  type: string;
  value: string;
  onChange: (newValue: string) => void;
}

const SocialMediaInput: React.FC<SocialMediaInputProps> = ({ type, value, onChange }) => {
  const getPrefix = (type: string) => {
    const prefixes: { [key: string]: string } = {
      'facebook': 'https://facebook.com/',
      'youtube': 'https://youtube.com/@',
      'instagram': 'https://instagram.com/',
      'x': 'https://twitter.com/',
      'linkedin': 'https://linkedin.com/in/',
      'tiktok': 'https://tiktok.com/@',
      'pinterest': 'https://pinterest.com/'
    };
  
    return prefixes[type.toLowerCase()] || '';
  };

  const prefix = getPrefix(type);

  const displayValue = prefix + value.replace(new RegExp(`^${prefix}`), '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    const normalizedText = text.startsWith(prefix) ? text.substring(prefix.length) : text;
    onChange(normalizedText.replace(/\s+/g, ''));
  };

  return (
    <div style={styles.inputContainer}>
      <input
        style={styles.input}
        value={displayValue}
        onChange={handleChange}
        placeholder={`Enter your ${type} username`}
        type="text"
        autoCapitalize="none"
      />
    </div>
  );
};

const styles = {
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    marginTop: '10px',
    paddingRight: '10px',
    paddingLeft: '10px',
    width: '100%',
  },
  input: {
    flex: 1,
    height: '40px',
    borderColor: 'gray',
    borderWidth: '1px',
    borderStyle: 'solid',
    paddingLeft: '10px',
    fontSize: '16px',
    color: 'white',
    backgroundColor: 'black',
    width: '100%',
    boxSizing: 'border-box' as 'border-box',
  }
};

export default SocialMediaInput;