import React, { useContext, useState, useEffect, useRef } from 'react';
import { RegisterContext } from '../context/RegisterContext';
import { AuthContext } from '../context/AuthContext';
import axios from '../Services/axiosInterceptor';
import { useNavigate } from 'react-router-dom';

const OitavaTela = () => {
  const { state: registerState } = useContext(RegisterContext);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const registrationAttempted = useRef(false);

  useEffect(() => {
    const handleRegister = async () => {
      if (registrationAttempted.current || !registerState.id_usuario || registerState.selectedGenres.length === 0) {
        return;
      }
  
      registrationAttempted.current = true;
  
      try {
        const userId = registerState.id_usuario;
        setProgress(0.5);
  
        const uniqueGenres = Array.from(new Set(registerState.selectedGenres));
        console.log('Enviando gêneros únicos:', uniqueGenres);
  
        for (const genre of uniqueGenres) {
          const postData = {
            id_usuario: userId,
            id_genero: genre
          };
          console.log(`Enviando gênero ${genre} para o usuário ${userId}`);
          try {
            const response = await axios.post(`https://tribhus.shop:5000/users/user-genres`, postData);
            console.log(`Resposta para gênero ${genre}:`, response.data.message);
          } catch (error) {
            if (error.response && error.response.status === 500) {
              console.error(`Erro ao enviar gênero ${genre} para o usuário ${userId}:`, error.response.data);
            } else {
              throw error;
            }
          }
        }
  
        console.log('Todos os gêneros processados');
        setProgress(1);
        setSuccess(true);
      } catch (error) {
        console.error('Erro ao salvar gêneros:', error);
        setError('Falha ao salvar gêneros, ' + (error instanceof Error ? error.message : "Erro desconhecido"));
      }
    };
  
    handleRegister();
  }, [registerState.id_usuario, registerState.selectedGenres]);

  useEffect(() => {
    const handleLogin = async () => {
      if (success && registerState.email && registerState.senha) {
        try {
          const loginSuccess = await login(registerState.email, registerState.senha);
          if (loginSuccess) {
            console.log('Login automático bem-sucedido');
            navigate('/TelaUsuarioPerfil');
          } else {
            setError('Falha no login automático. Por favor, faça login manualmente.');
          }
        } catch (error) {
          console.error('Erro durante o login automático:', error);
          setError('Erro durante o login automático. Por favor, tente fazer login manualmente.');
        }
      }
    };

    handleLogin();
  }, [success, registerState.email, registerState.senha, login, navigate]);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#fff',
      height: '100vh',
      padding: '20px',
    },
    logo: {
      width: '280px',
      height: '360px',
      objectFit: 'contain' as 'contain',
      marginBottom: '50px',
      marginTop: '10px',
    },
    progressBar: {
      width: '200px',
      height: '8px',
      backgroundColor: '#e6e6e6',
      borderRadius: '4px',
      overflow: 'hidden',
    },
    progressFill: {
      width: `${progress * 100}%`,
      height: '100%',
      backgroundColor: '#914100',
      transition: 'width 0.5s ease-in-out',
    },
    loadingText: {
      fontSize: '20px',
      color: '#000',
      marginTop: '20px',
    },
    successText: {
      fontSize: '20px',
      color: 'green',
      marginTop: '20px',
    },
    errorText: {
      fontSize: '20px',
      color: 'red',
      marginTop: '20px',
    },
  };

  return (
    <div style={styles.container}>
      <img src={require('../assets/logotipo.png')} style={styles.logo} alt="Logotipo" />
      <div style={styles.progressBar}>
        <div style={styles.progressFill}></div>
      </div>
      {success ? (
        <span style={styles.successText}>Registro concluído com sucesso!</span>
      ) : (
        <span style={styles.loadingText}>Aguarde um momento</span>
      )}
      {error && <span style={styles.errorText}>{error}</span>}
    </div>
  );
};

export default OitavaTela;