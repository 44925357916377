import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from '../Services/axiosInterceptor'; 
import PlaylistModal from './PlaylistModal';

interface Playlist {
  id_playlist: number;
  nome: string;
  ultimasImagens: string[];
}

interface PlaylistUserProps {
  idUsuario: number;
  token: string;
}

const PlaylistUser: React.FC<PlaylistUserProps> = ({ token }) => {
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [selectedPlaylistId, setSelectedPlaylistId] = useState<string | null>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const fetchPlaylist = useCallback(async () => {
    try {
      const response = await axios.get<Playlist[]>(`https://tribhus.shop:5000/playlists`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      const playlistsComImagens: Playlist[] = response.data.map(pl => ({
        ...pl,
        ultimasImagens: pl.ultimasImagens || []
      }));
      setPlaylists(playlistsComImagens);
  
      playlistsComImagens.forEach(pl => {
        fetchUltimasImagens(pl.id_playlist);
      });
  
    } catch (error) {
      console.error("Erro ao buscar playlists:", error);
    }
  }, [token]);

  const fetchUltimasImagens = useCallback(async (playlistId: number) => {
    try {
      const response = await axios.get<string[]>(`https://tribhus.shop:5000/playlists/${playlistId}/ultimas-imagens`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setPlaylists(prevPlaylists => prevPlaylists.map(playlist => {
        if (playlist.id_playlist === playlistId) {
          return { ...playlist, ultimasImagens: response.data };
        }
        return playlist;
      }));
    } catch (error) {
      console.error("Erro ao buscar últimas imagens das músicas da playlist:", error);
    }
  }, [token]);

  useEffect(() => {
    fetchPlaylist();
  }, [fetchPlaylist]);

  const handlePlaylistClick = (playlist: Playlist) => {
    setSelectedPlaylistId(playlist.id_playlist.toString());
  };

  const handleCloseModal = () => {
    setSelectedPlaylistId(null);
  };

  const handleScroll = (direction: 'left' | 'right') => {
    if (listRef.current) {
      const scrollAmount = 200;
      if (direction === 'left') {
        listRef.current.scrollLeft -= scrollAmount;
      } else {
        listRef.current.scrollLeft += scrollAmount;
      }
      updateArrows();
    }
  };

  const updateArrows = useCallback(() => {
    if (listRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  }, []);

  useEffect(() => {
    const list = listRef.current;
    if (list) {
      list.addEventListener('scroll', updateArrows);
      return () => list.removeEventListener('scroll', updateArrows);
    }
    return () => {};
  }, [updateArrows]);

  return (
    <div>
      <h2 style={styles.sectionTitle}>Minhas Playlists</h2>
      <div style={styles.container}>
        {showLeftArrow && (
          <div style={{ ...styles.arrow, ...styles.leftArrow }} onClick={() => handleScroll("left")}>
            &#9664;
          </div>
        )}
        <div style={styles.list} ref={listRef}>
          {playlists.map((playlist) => (
            <div key={playlist.id_playlist} style={styles.card} onClick={() => handlePlaylistClick(playlist)}>
              <div style={styles.gridContainer}>
                {playlist.ultimasImagens && playlist.ultimasImagens.length > 0 ? (
                  playlist.ultimasImagens.slice(0, 4).map((imagem, index) => (
                    <img
                      key={index}
                      src={imagem}
                      alt={`Capa ${index + 1}`}
                      style={index % 2 === 0 ? styles.gridImageLeft : styles.gridImageRight}
                    />
                  ))
                ) : (
                  <div style={styles.noImagePlaceholder}>Sem imagens</div>
                )}
              </div>
              <p style={styles.title}>{playlist.nome}</p>
            </div>
          ))}
        </div>
        {showRightArrow && (
          <div style={{ ...styles.arrow, ...styles.rightArrow }} onClick={() => handleScroll("right")}>
            &#9654;
          </div>
        )}
      </div>
      {selectedPlaylistId && (
        <PlaylistModal playlistId={selectedPlaylistId} onClose={handleCloseModal} />
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    position: 'relative',
    maxWidth: '100%',
    margin: '0 auto',
    alignItems: 'center',
  } as React.CSSProperties,
  sectionTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#FF6600',
    margin: 0,
    marginTop: '40px',
  } as React.CSSProperties,
  list: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    scrollSnapType: 'x mandatory',
    scrollBehavior: 'smooth',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    padding: '0 20px',
  } as React.CSSProperties,
  card: {
    flex: '0 0 auto',
    scrollSnapAlign: 'start',
    margin: 5,
    padding: 10,
    borderRadius: 10,
    position: 'relative',
    width: '200px',
    backgroundColor: '#202020',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  } as React.CSSProperties,
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '180px',
    height: '180px',
    backgroundColor: '#000',
  } as React.CSSProperties,
  gridImageLeft: {
    width: '49%',
    height: '49%',
    marginBottom: '2%',
    objectFit: 'cover',
  } as React.CSSProperties,
  gridImageRight: {
    width: '49%',
    height: '49%',
    marginBottom: '2%',
    marginLeft: '2%',
    objectFit: 'cover',
  } as React.CSSProperties,
  noImagePlaceholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFF',
    fontSize: '12px',
  } as React.CSSProperties,
  title: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
    margin: '10px 0',
    textAlign: 'center',
  } as React.CSSProperties,
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    zIndex: 10,
    transition: 'opacity 0.3s',
    opacity: 0.5,
  } as React.CSSProperties,
  leftArrow: {
    left: '10px',
  } as React.CSSProperties,
  rightArrow: {
    right: '10px',
  } as React.CSSProperties,
};

export default PlaylistUser;