import React, { useState, useEffect, useContext } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import MusicContext from '../context/MusicContext';
import { toast } from 'react-toastify';

interface Music {
    id_musica: number;
    id_banda: number;
    id_album: number;
    nome_musica: string;
    url_musica: string;
    url_imagem: string;
    nome_banda: string;
    likes_count: number;
    has_liked: boolean;
    user_or_banda_liked_id: number | null;
}

interface PlaylistDetails {
  id_playlist: string;
  nome_playlist: string;
  descricao: string;
  url_capa_playlist: string;
  data_criacao: string;
}

interface PlaylistModalProps {
  playlistId: string;
  onClose: () => void;
}

const PlaylistModal: React.FC<PlaylistModalProps> = ({ playlistId, onClose }) => {
  const [playlistDetails, setPlaylistDetails] = useState<PlaylistDetails | null>(null);
  const [musicas, setMusicas] = useState<Music[]>([]);
  const [ultimasImagens, setUltimasImagens] = useState<string[]>([]);
  const { state: authState } = useContext(AuthContext);
  const musicContext = useContext(MusicContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlaylistDetailsAndMusic = async () => {
      try {
        const [detailsResponse, musicResponse, imagesResponse] = await Promise.all([
          axios.get<PlaylistDetails>(`https://tribhus.shop:5000/playlists/${playlistId}/detalhes`, {
            headers: { Authorization: `Bearer ${authState.token}` },
          }),
          axios.get<Music[]>(`https://tribhus.shop:5000/playlists/${playlistId}/musicas`, {
            headers: { Authorization: `Bearer ${authState.token}` },
          }),
          axios.get<string[]>(`https://tribhus.shop:5000/playlists/${playlistId}/ultimas-imagens`, {
            headers: { Authorization: `Bearer ${authState.token}` },
          })
        ]);

        setPlaylistDetails(detailsResponse.data);
        setMusicas(musicResponse.data);
        setUltimasImagens(imagesResponse.data);
      } catch (error) {
        console.error("Erro ao buscar detalhes, músicas ou imagens da playlist:", error);
      }
    };
    
    fetchPlaylistDetailsAndMusic();
  }, [playlistId, authState.token]);

  const handlePlayPlaylist = () => {
    if (musicContext && musicas.length) {
      musicContext.setPlaylist(musicas);
      musicContext.selectTrack(musicas[0]);
      navigate('/AudioPlayer');
    }
  };

  const handleAddPlaylistToQueue = () => {
    if (musicContext && musicas.length) {
      musicContext.addToPlaylist(musicas);
      toast.success('Músicas da playlist adicionadas com sucesso!');
    }
  };

  const handleTrackPlay = (track: Music) => {
    if (musicContext) {
      musicContext.setPlaylist([track, ...musicas.filter(m => m.id_musica !== track.id_musica)]);
      musicContext.selectTrack(track);
      navigate('/AudioPlayer');
    }
  };

  const handleTrackAdd = (track: Music) => {
    if (musicContext) {
      musicContext.addToPlaylist([track]);
      toast.success('Música adicionada com sucesso!');
    }
  };

  if (!playlistDetails || !musicContext) {
    return <div>Carregando...</div>;
  }

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>Fechar</button>
        <div style={styles.content}>
          <div style={styles.gridContainer}>
            {ultimasImagens.length > 0 ? (
              ultimasImagens.slice(0, 4).map((imagem, index) => (
                <img
                  key={index}
                  src={imagem}
                  alt={`Capa ${index + 1}`}
                  style={index % 2 === 0 ? styles.gridImageLeft : styles.gridImageRight}
                />
              ))
            ) : (
              <div style={styles.noImagePlaceholder}>Sem imagens</div>
            )}
          </div>
          <div style={styles.playlistInfo}>
            <h1 style={styles.playlistTitle}>{playlistDetails.nome_playlist}</h1>
            <h2 style={styles.playlistDescription}>{playlistDetails.descricao}</h2>
            <div style={styles.buttonGroup}>
              <button style={styles.actionButton} onClick={handlePlayPlaylist}>Reproduzir playlist</button>
              <button style={styles.actionButton} onClick={handleAddPlaylistToQueue}>Adicionar playlist à fila</button>
            </div>
          </div>
        </div>
        <div style={styles.tracks}>
          <h2 style={styles.tracksTitle}>Músicas:</h2>
          {musicas.map(track => (
            <div key={track.id_musica} style={styles.track}>
              <span style={styles.trackTitle}>{track.nome_musica}</span>
              <div style={styles.trackButtonGroup}>
                <button style={styles.trackButton} onClick={() => handleTrackPlay(track)}>Reproduzir</button>
                <button style={styles.trackButton} onClick={() => handleTrackAdd(track)}>Adicionar à fila</button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  } as React.CSSProperties,
  modal: {
    backgroundColor: '#202020',
    padding: '20px',
    width: '60%',
    maxHeight: '80%',
    overflowY: 'auto',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  } as React.CSSProperties,
  closeButton: {
    alignSelf: 'flex-end',
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
  } as React.CSSProperties,
  content: {
    display: 'flex',
    gap: '20px',
  } as React.CSSProperties,
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    gap: '5px',
    width: '150px',
    height: '150px',
  } as React.CSSProperties,
  gridImageLeft: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  } as React.CSSProperties,
  gridImageRight: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
  } as React.CSSProperties,
  noImagePlaceholder: {
    width: '150px',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#333',
    color: '#fff',
    borderRadius: '10px',
  } as React.CSSProperties,
  playlistInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '10px',
  } as React.CSSProperties,
  playlistTitle: {
    color: 'white',
    fontSize: '24px',
    margin: 0,
  } as React.CSSProperties,
  playlistDescription: {
    color: '#ccc',
    fontSize: '18px',
    margin: 0,
  } as React.CSSProperties,
  buttonGroup: {
    display: 'flex',
    gap: '10px',
  } as React.CSSProperties,
  actionButton: {
    padding: '10px',
    backgroundColor: '#914100',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    flex: 1,
    textAlign: 'center',
  } as React.CSSProperties,
  tracks: {
    marginTop: '20px',
  } as React.CSSProperties,
  tracksTitle: {
    color: 'white',
    fontSize: '20px',
    marginBottom: '10px',
  } as React.CSSProperties,
  track: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0',
    borderBottom: '1px solid #444',
  } as React.CSSProperties,
  trackTitle: {
    color: 'white',
    flex: 1,
    marginRight: '10px',
  } as React.CSSProperties,
  trackButtonGroup: {
    display: 'flex',
    gap: '10px',
  } as React.CSSProperties,
  trackButton: {
    backgroundColor: '#555',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
    cursor: 'pointer',
  } as React.CSSProperties,
};

export default PlaylistModal;