import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import MusicContext from '../context/MusicContext';
import axios from '../Services/axiosInterceptor'; 
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface Music {
    id_musica: number;
    id_banda: number;
    id_album: number;
    nome_musica: string;
    url_musica: string;
    url_imagem: string;
    nome_banda: string;
    likes_count: number;
    has_liked: boolean;
    user_or_banda_liked_id: number | null;
    compositor: string;
    ano_lancamento: number;
}

interface MusicasBandaProps {
  idBanda: number;
}

const MusicasBanda: React.FC<MusicasBandaProps> = ({ idBanda }) => {
  const [tracks, setTracks] = useState<Music[]>([]);
  const { state: authState } = useContext(AuthContext);
  const musicContext = useContext(MusicContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTrack, setSelectedTrack] = useState<Music | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (authState.token && idBanda) {
      axios.get<Music[]>(`https://tribhus.shop:5000/bands/band-tracks/${idBanda}`, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      })
      .then(response => {
        console.log('Resposta do backend no musicasbanda:', response);  // Log da resposta do backend
        setTracks(response.data);
      })
      .catch(err => console.log('Erro na requisição:', err));  // Log do erro
    }
  }, [idBanda, authState.token]);  
 
  const handlePressTrack = (track: Music) => {
    setSelectedTrack(track);
    setModalVisible(true);
  };

  const handlePlay = () => {
    if (musicContext && selectedTrack) {
      const updatedPlaylist = [selectedTrack, ...musicContext.state.playlist];
      musicContext.setPlaylist(updatedPlaylist);
      musicContext.selectTrack(selectedTrack);
      navigate('/AudioPlayer');
    }
    setModalVisible(false);
  };

  const handleAddToPlayer = () => {
    if (musicContext && selectedTrack) {
      musicContext.addToPlaylist([selectedTrack]);
      toast.success('Música adicionada com sucesso!');
    }
    setModalVisible(false);
  };

  const styles = {
    container: {
      backgroundColor: '#151922',
      overflowY: 'auto' as const,
      padding: '10px 0',
      marginBottom: '20px', // Adicionado para dar espaço entre MusicasBanda e o próximo componente
    },
    trackImage: {
      width: 40,
      height: 40,
      marginRight: 10,
      borderRadius: '4px',
    },
    trackDetailContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    trackNumber: {
      marginRight: 10,
      color: 'white',
      fontSize: 14,
    },
    trackInfo: {
      display: 'flex',
      flexDirection: 'column' as const,
    },
    trackComposer: {
      fontSize: 12,
      color: '#914100',
    },
    trackContainer: {
      backgroundColor: '#0E1119',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 10px',
      margin: '2px 10px',
      borderRadius: 8,
      cursor: 'pointer',
    },
    trackName: {
      fontSize: 14,
      color: 'lightgray',
    },
    titleContainer: {
      marginTop: 10,
      marginBottom: 10,
      paddingLeft: 10,
    },
    titleText: {
      fontSize: 20,
      color: '#FF6600',
      fontWeight: 'bold',
      margin: 0,
    },
    modal: {
      position: 'fixed' as const,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    modalContent: {
      backgroundColor: '#914100CC',
      padding: 10,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      width: '100%',
      maxHeight: '40%',
    },
    modalButton: {
      width: '100%',
      padding: 10,
      textAlign: 'center' as const,
      cursor: 'pointer',
    },
    modalText: {
      fontSize: 16,
      color: 'lightgray',
      letterSpacing: -0.5,
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.titleContainer}>
        <h2 style={styles.titleText}>Músicas</h2>
      </div>
      {tracks.map((track, index) => (
        <div key={track.id_musica} style={styles.trackContainer} onClick={() => handlePressTrack(track)}>
          <div style={styles.trackDetailContainer}>
            <span style={styles.trackNumber}>{`${index + 1}.`}</span>
            <img src={track.url_imagem} alt={track.nome_musica} style={styles.trackImage} />
            <div style={styles.trackInfo}>
              <span style={styles.trackName}>{track.nome_musica}</span>
              <span style={styles.trackComposer}>{track.compositor} - {track.ano_lancamento}</span>
            </div>
          </div>
        </div>
      ))}

      {modalVisible && (
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            <div style={styles.modalButton} onClick={handlePlay}>
              <span style={styles.modalText}>Reproduzir</span>
            </div>
            <div style={styles.modalButton} onClick={handleAddToPlayer}>
              <span style={styles.modalText}>Adicionar ao Player</span>
            </div>
            <div style={styles.modalButton} onClick={() => setModalVisible(false)}>
              <span style={styles.modalText}>Cancelar</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MusicasBanda;