import React, { useState, CSSProperties, useContext } from 'react';
import { RegisterContext } from '../context/RegisterContext'; 
import axios from '../Services/axiosInterceptor'; 

const TelaEdiUsuario: React.FC = () => {
  const { state, dispatch } = useContext(RegisterContext);
  const [descricaoUsuario, setDescricaoUsuario] = useState(state?.descricao || '');
  const token = localStorage.getItem('token');

  const updateDescricao = () => {
    console.log("ID do usuário sendo enviado:", state.id_usuario); // Log para rastrear o valor

    axios({
      method: 'put',
      url: `https://tribhus.shop:5000/users/profile/${state.id_usuario}`,
      data: {
        descricao: descricaoUsuario,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      console.log('Response data:', response.data);
      setDescricaoUsuario(response.data.descricao);

      // Atualize o estado do UsuarioContext aqui
      dispatch({ type: 'UPDATE_DESCRICAO_USUARIO', descricao: response.data.descricao });
    })
    .catch(() => {
      alert(
        'Erro: Ocorreu um erro ao atualizar a descrição. Por favor, tente novamente.'
      );
    });
  };

  return (
    <div style={styles.editContainer}>
      <div style={styles.descriptionBox}>
        <textarea
          style={styles.input}
          rows={4}
          onChange={(e) => setDescricaoUsuario(e.target.value)}
          value={descricaoUsuario}
        />
        <button onClick={updateDescricao} style={styles.saveButton}>
          Salvar Descrição
        </button>
      </div>
    </div>
  );
};

const styles: Record<string, CSSProperties> = {
  editContainer: {
    margin: '0 auto',
    padding: '20px',
    maxWidth: '600px',
  },
  descriptionBox: {
    backgroundColor: '#151922',
    padding: '30px',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  input: {
    width: '100%',
    marginBottom: '10px',
    margin: '10px 0',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '15px',
  },
  saveButton: {
    backgroundColor: '#914100',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '30px',
    cursor: 'pointer',
  }
};

export default TelaEdiUsuario;
