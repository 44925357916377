import React, { useEffect, useRef, useState } from 'react';
import axios from '../Services/axiosInterceptor'; 
import ModalVideo from './ModalVideo';

interface Video {
  id_video: number;
  url_video: string;
  titulo: string;
  descricao: string;
}

interface BandaVideosProps {
  id_banda: number;
  token: string;
}

const BandaVideos: React.FC<BandaVideosProps> = ({ id_banda, token }) => {
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
  const [videos, setVideos] = useState<Video[]>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const handleVideoClick = (video: Video) => {
    setSelectedVideo(video);
  };

  useEffect(() => {

    console.log('id_banda em videos:', id_banda);
    console.log('token em videos:', token);

    const fetchVideos = async () => {
      try {
        const response = await axios.get<Video[]>(`https://tribhus.shop:5000/videos/band/${id_banda}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setVideos(response.data);
      } catch (error) {
        console.error('Erro ao buscar vídeos:', error);
      }
    };

    if (token) {
      fetchVideos();
    }
  }, [id_banda, token]);

  const handleScroll = (direction: string) => {
    if (direction === "left") {
      listRef.current?.scrollBy({
        top: 0,
        left: -window.innerWidth / 2,
        behavior: "smooth"
      });
    } else {
      listRef.current?.scrollBy({
        top: 0,
        left: window.innerWidth / 2,
        behavior: "smooth"
      });
    }
  };

  const checkArrowsVisibility = () => {
    if (listRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener('scroll', checkArrowsVisibility);
    }
    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener('scroll', checkArrowsVisibility);
      }
    };
  }, []);

  const getYoutubeThumbnail = (url: string) => {
    let videoId = '';
  
    if (url.includes('youtu.be')) {
      // Extrai o ID do vídeo das URLs encurtadas
      videoId = url.split('youtu.be/')[1]?.split('?')[0];
    } else if (url.includes('youtube.com')) {
      // Extrai o ID do vídeo das URLs completas
      videoId = url.split('v=')[1]?.split('&')[0];
    }
  
    return `https://img.youtube.com/vi/${videoId}/0.jpg`;
  };  

  return (
    <div>
      <p style={styles.sectionTitle}>Vídeos da Banda</p>
      <div style={styles.container}>
        {showLeftArrow && (
          <div style={{ ...styles.arrow, ...styles.leftArrow }} onClick={() => handleScroll("left")}>
            &#9664;
          </div>
        )}
        <div style={styles.list} ref={listRef}>
          {videos.map((video: Video) => (
            <div key={video.id_video} style={styles.card} onClick={() => handleVideoClick(video)}>
              <div style={styles.thumbnailContainer}>
                <img
                  src={getYoutubeThumbnail(video.url_video)}
                  alt={video.titulo}
                  style={styles.thumbnail}
                />
                <div style={styles.playButton}>▶</div>
              </div>
            </div>
          ))}
        </div>
        {showRightArrow && (
          <div style={{ ...styles.arrow, ...styles.rightArrow }} onClick={() => handleScroll("right")}>
            &#9654;
          </div>
        )}
        {selectedVideo && (
          <ModalVideo
            isOpen={true}
            video={selectedVideo}
            onClose={() => setSelectedVideo(null)}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row' as const,
    overflow: 'hidden',
    position: 'relative' as const,
    maxWidth: '100%',
    margin: '0 auto',
    alignItems: 'center',
  },
  sectionTitle: {
    fontSize: '20px',
    fontWeight: 'bold' as const,
    color: '#FF6600',
    margin: 0,
    marginTop: '40px',
  },
  list: {
    display: 'flex',
    flexDirection: 'row' as const,
    overflowX: 'scroll' as const,
    scrollSnapType: 'x mandatory' as const,
    scrollBehavior: 'smooth' as const,
    scrollbarWidth: 'none' as const,
    msOverflowStyle: 'none' as const,
    padding: '0 20px',
  },
  card: {
    flex: '0 0 auto',
    scrollSnapAlign: 'start' as const,
    margin: 5,
    padding: 10,
    borderRadius: 10,
    position: 'relative' as const,
    width: '200px',
    backgroundColor: '#202020',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  thumbnailContainer: {
    position: 'relative' as const,
    width: '180px',
    height: '180px',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'cover' as const,
  },
  playButton: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '48px',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  title: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
    margin: '10px 0',
    textAlign: 'center' as const,
  },
  arrow: {
    position: 'absolute' as const,
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    zIndex: 10,
    transition: 'opacity 0.3s',
    opacity: 0.5,
  },
  leftArrow: {
    left: '10px',
  },
  rightArrow: {
    right: '10px',
  },
};

export default BandaVideos;