import React, { useState, useEffect, useContext } from 'react';
import axios from '../Services/axiosInterceptor'; 
import { AuthContext } from '../context/AuthContext'; // Ajuste o caminho conforme necessário
import ModalNotificacoes from './ModalNotificacoes'; // Importe o componente do modal
import notificationIcon from '../assets/Notification.png'; // Ajuste o caminho conforme necessário

interface Notificacao {
  id_notificacao: number;
  status: 'lida' | 'não lida';
  mensagem: string;
  tipo_notificacao: string;
  // Adicione outros campos conforme necessário
}

interface NotificacoesProps {
  idUsuarioRecebe?: number;
  idBandaRecebe?: number;
  isOwnProfile: boolean;
}

const Notificacoes: React.FC<NotificacoesProps> = ({ idUsuarioRecebe, idBandaRecebe, isOwnProfile }) => {
  const [notificacoes, setNotificacoes] = useState<Notificacao[]>([]);
  const [temNotificacoesNovas, setTemNotificacoesNovas] = useState(false);
  const [modalAberto, setModalAberto] = useState(false);
  const { state: authState } = useContext(AuthContext);

  useEffect(() => {
    if (authState.token && (idUsuarioRecebe || idBandaRecebe)) {
      fetchNotificacoes();
    }
  }, [authState.token, idUsuarioRecebe, idBandaRecebe]);

  const fetchNotificacoes = async () => {
    if (!authState.token) {
      console.log('Nenhum token disponível. Usuário provavelmente deslogado.');
      return;
    }

    try {
      const url = `https://tribhus.shop:5000/notificacoes/all/${idUsuarioRecebe}/${idBandaRecebe}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${authState.token}`
        }
      });

      const novasNotificacoes = response.data.some((notificacao: Notificacao) => notificacao.status === 'não lida');
      setTemNotificacoesNovas(novasNotificacoes);
      setNotificacoes(response.data);
    } catch (error) {
      console.error('Erro ao buscar notificações:', error);
    }
  };

  const handleNotificacoesClick = async () => {
    await fetchNotificacoes();
    setModalAberto(true);
  };

  const handleFecharModal = () => {
    setModalAberto(false);
  };

  const handleNotificacaoClick = (idConversa: number) => {
    console.log('Notificação clicada, id da conversa:', idConversa);
    // Implemente a lógica necessária para lidar com o clique na notificação
  };

  if (!isOwnProfile) {
    return null;
  }

  return (
    <>
      <div 
        onClick={handleNotificacoesClick} 
        style={styles.notificationIconContainer}
      >
        <img src={notificationIcon} alt="Notificações" style={styles.notificationIcon} />
        {temNotificacoesNovas && (
          <div style={styles.pontoExclamacao}>
            <span style={styles.textoPontoExclamacao}>!</span>
          </div>
        )}
      </div>

      <ModalNotificacoes
        isOpen={modalAberto}
        onClose={handleFecharModal}
        notificacoes={notificacoes}
        onNotificacaoClick={handleNotificacaoClick}
      />
    </>
  );
};

const styles = {
  notificationIconContainer: {
    position: 'relative' as const,
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
  notificationIcon: {
    width: '100%',
    height: '100%',
  },
  pontoExclamacao: {
    position: 'absolute' as const,
    top: '-5px',
    right: '-5px',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    backgroundColor: 'red',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textoPontoExclamacao: {
    color: 'white',
    fontSize: '12px',
    fontWeight: 'bold' as const,
  },
};

export default Notificacoes;