import React, { useState, useEffect, useContext } from 'react';
import axios from '../Services/axiosInterceptor'; 
import YouTube from 'react-youtube';
import LikeButton from '../Components/LikeButton';
import { AuthContext } from '../context/AuthContext';
import { SlugContext } from '../context/SlugContext';
import { useNavigate } from 'react-router-dom';
import BotaoDenuncias from '../Components/BotaoDenuncias';
import noImage from '../assets/no_image.png';

interface PostagemProps {
  isOpen: boolean;
  onClose: () => void;
  postId: number;
}

interface Post {
  id_postagem: number;
  conteudo: string;
  data_hora: string;
  nome: string;
  sobrenome: string;
  url_capa: string;
  image_url?: string;
  video_url?: string;
  likes_count: number;
  user_or_banda_liked_id: number | null;
  slug: string;
  id_usuario?: number;
  id_banda?: number;
}

interface Comment {
  id_comentario: number;
  texto: string;
  data_hora: string;
  nome: string;
  sobrenome?: string;
  url_perfil?: string;
  id_usuario?: number;
  id_banda?: number;
  slug: string;
}

const extractYouTubeVideoId = (url: string): string | null => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
};

const YouTubeVideo: React.FC<{ videoId: string }> = ({ videoId }) => {
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0,
    },
  };

  return <YouTube videoId={videoId} opts={opts} />;
};

const Postagem: React.FC<PostagemProps> = ({ isOpen, onClose, postId }) => {
  const [post, setPost] = useState<Post | null>(null);
  const [comments, setComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState('');
  const { state: authState } = useContext(AuthContext);
  const { id_usuario, id_banda, token } = authState;
  const { dispatch: slugDispatch } = useContext(SlugContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPostAndComments = async () => {
      try {
        const postResponse = await axios.get(`https://tribhus.shop:5000/palco/postid/${postId}`, {
          params: { id_banda, id_usuario },
          headers: { Authorization: `Bearer ${token}` },
        });
        setPost(postResponse.data);

        const commentsResponse = await axios.get(`https://tribhus.shop:5000/palco/${postId}/comments`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setComments(commentsResponse.data);
      } catch (error) {
        console.error("Erro ao buscar postagem e comentários:", error);
      }
    };

    if (isOpen && postId) {
      fetchPostAndComments();
    }
  }, [postId, id_banda, id_usuario, token, isOpen]);

  const handleCreateComment = async () => {
    const commentData = {
      texto: newComment,
      id_postagem: postId,
      id_usuario: id_usuario,
      id_banda: id_banda
    };

    try {
      await axios.post('https://tribhus.shop:5000/palco/comment', commentData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const commentsResponse = await axios.get(`https://tribhus.shop:5000/palco/${postId}/comments`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setComments(commentsResponse.data);
      setNewComment('');
    } catch (error) {
      console.error("Erro ao criar comentário:", error);
    }
  };

  const navigateToProfile = (slug: string, id_usuario?: number, id_banda?: number) => {
    slugDispatch({ type: 'SET_SLUG_PERFIL', slugPerfil: slug });
    
    if (id_usuario) {
      navigate('/TelaUsuarioPerfil');
    } else if (id_banda) {
      navigate('/TelaBandaPerfil');
    }
  };

  if (!isOpen || !post) return null;

  const videoId = post.video_url ? extractYouTubeVideoId(post.video_url) : null;

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };

return (
  <div style={styles.modalOverlay} onClick={onClose}>
    <div
      style={styles.modalContainer}
      onClick={(e) => e.stopPropagation()} // Evita que o clique no conteúdo do modal feche o modal
    >
      <button onClick={onClose} style={styles.modalCloseButton}>
        &times;
      </button>
      <div style={styles.contentContainer}>
        <div style={styles.header}>
          <div
            style={styles.authorInfo}
            onClick={() =>
              navigateToProfile(post.slug, post.id_usuario, post.id_banda)
            }
          >
            <img
              src={post.url_capa && post.url_capa.trim() !== "https://sate.live:9000/tribhusmidias/" ? post.url_capa : noImage} // Verifica se há imagem do autor da postagem, caso contrário, exibe imagem padrão
              alt="Autor"
              style={styles.authorImage}
            />
            <h2 style={styles.authorName}>
              {post.nome} {post.sobrenome}
            </h2>
          </div>
        </div>

        <div style={styles.postContentContainer}>
          {post.image_url && (
            <img
              src={post.image_url}
              alt="Imagem da postagem"
              style={styles.postImage}
            />
          )}
          {videoId && (
            <div style={styles.videoContainer}>
              <YouTubeVideo videoId={videoId} />
            </div>
          )}
          <p style={styles.postContent}>{post.conteudo}</p>
        </div>

        <p style={styles.postDate}>Data: {formatDate(post.data_hora)}</p>

        <div style={styles.postActionsContainer}>
          <LikeButton
            postId={post.id_postagem}
            initialLikes={post.likes_count ?? 0}
            user_or_banda_liked_id={post.user_or_banda_liked_id ?? null}
            liked={Boolean(post.user_or_banda_liked_id)}
            contentType="postagem"
            slug={post.slug}
          />

          <BotaoDenuncias
            itemId={post.id_postagem}
            tipoItem="postagem"
            onDenunciaEnviada={() => console.log("Denúncia de postagem enviada com sucesso!")}
          />
        </div>

        <div style={styles.commentSection}>
          <h3 style={styles.commentTitle}>Comentários:</h3>
          {comments.map((comment) => (
            <div key={comment.id_comentario} style={styles.commentContainer}>
              <div
                style={styles.commentAuthorInfo}
                onClick={() =>
                  navigateToProfile(comment.slug, comment.id_usuario, comment.id_banda)
                }
              >
                <img
                  src={comment.url_perfil && comment.url_perfil.trim() !== "https://sate.live:9000/tribhusmidias/" ? comment.url_perfil : noImage} // Verifica se há imagem do autor do comentário, caso contrário, exibe imagem padrão
                  alt="Perfil"
                  style={styles.commentAuthorImage}
                />
                <span style={styles.commentAuthorName}>
                  {comment.nome} {comment.sobrenome}
                </span>
              </div>

              {/* Envolver o texto do comentário e o botão de denúncia em um novo contêiner */}
              <div style={styles.commentTextContainer}>
                <p style={styles.commentText}>{comment.texto}</p>
                <BotaoDenuncias
                  itemId={comment.id_comentario}
                  tipoItem="comentario"
                  onDenunciaEnviada={() => console.log("Denúncia de comentário enviada com sucesso!")}
                />
              </div>
              <p style={styles.commentDate}>{formatDate(comment.data_hora)}</p>
            </div>
          ))}
        </div>

        <div style={styles.newCommentContainer}>
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            style={styles.newCommentInput}
            placeholder="Adicione um comentário..."
          />
          <button
            onClick={handleCreateComment}
            style={styles.commentButton}
          >
            Comentar
          </button>
        </div>
      </div>
    </div>
  </div>
);
};

const styles: { [key: string]: React.CSSProperties } = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Cor de fundo com opacidade
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: '#151922', // Cor de fundo do modal
    padding: '5px',
    borderRadius: '5px',
    maxWidth: '780px', // Aumenta a largura máxima do modal em 30%
    width: '90%',
    maxHeight: '90vh',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
    zIndex: 1000,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '20px',
  },
  modalCloseButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '24px',
    color: 'white',
  },
  contentContainer: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden', // Remove a barra de rolagem horizontal
    padding: '5px',
    width: '95%', // Reduz a largura do conteúdo para criar espaçamento dentro do modal
    margin: '0 auto', // Centraliza o conteúdo dentro do modal
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  authorInfo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '1px',
    marginLeft: '1px',
  },
  authorImage: {
    width: '40px',
    height: '40px',
    borderRadius: '20px',
    marginRight: '8px',
  },
  authorName: {
    color: 'white',
    fontWeight: 'bold',
  },
  postContentContainer: {
    backgroundColor: '#000',
    padding: '10px',
    borderRadius: '10px',
    marginBottom: '16px',
   // textAlign: 'center', // Centraliza o conteúdo no contêiner
  },
  postImage: {
    maxWidth: '80%', // Limita a largura máxima da imagem a 80% da largura do contêiner
    height: 'auto',
    borderRadius: '10px',
    marginBottom: '10px',
    backgroundColor: '#000',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto', // Centraliza a imagem no contêiner
  },
  postContent: {
    color: 'white',
    marginBottom: '16px',
  },
  videoContainer: {
    maxWidth: '100%', // Garante que o vídeo ocupe a largura total disponível no contêiner
    height: 'auto',
    marginBottom: '16px',
    borderRadius: '10px', // Adiciona bordas arredondadas ao vídeo
    overflow: 'hidden', // Garante que o conteúdo do vídeo não saia dos limites do contêiner
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto', // Centraliza o vídeo no contêiner
  },
  postDate: {
    fontSize: '12px',
    color: '#545C6F',
    marginTop: '4px',
  },
  commentSection: {
    borderTop: '1px solid #ddd',
    paddingTop: '16px',
    marginTop: '16px',
  },
  commentTextContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Alinha o texto à esquerda e o botão à direita
    width: '100%',
    marginBottom: '5px',
  },
  commentText: {
    color: 'white',
    marginBottom: 0, // Remove a margem inferior para alinhamento adequado
    marginRight: '10px', // Espaço entre o texto e o botão
    flex: 1, // Permite que o texto ocupe o espaço disponível
  },
  commentTitle: {
    fontWeight: 'bold',
    marginBottom: '8px',
    color: 'white',
  },
  commentContainer: {
    marginBottom: '16px',
    color: 'white',
    padding: '10px',
  },
  commentAuthorInfo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '8px',
  },
  commentAuthorImage: {
    width: '30px',
    height: '30px',
    borderRadius: '15px',
    marginRight: '8px',
  },
  commentAuthorName: {
    fontWeight: 'bold',
    color: 'white',
    fontSize: '10px',
  },
  commentDate: {
    fontSize: '10px',
    color: 'gray',
    marginTop: '8px',
  },
  newCommentContainer: {
    marginTop: '16px',
    padding: '10px',
    borderColor: 'gray',
    borderWidth: '1px',
    borderRadius: '5px',
  },
  newCommentInput: {
    width: '100%',
    padding: '10px',
    border: '1px solid gray',
    borderRadius: '5px',
    color: 'white',
    backgroundColor: '#151922',
  },
  commentButton: {
    padding: '10px 20px',
    backgroundColor: '#914100',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  moreCommentsText: {
    color: 'white',
    fontSize: '14px',
    padding: '10px',
    textAlign: 'center',
  },
  postActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Distribui o espaço entre os itens
    width: '100%', // Garante que o contêiner ocupe toda a largura
  },
  
  denunciaButton: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '1px',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#ff6347', // Mantém a cor do botão de denúncia
   },
};

export default Postagem;