import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import BotaoDenunciaPerfil from '../Components/BotaoDenunciaPerfil';

interface ModalMenuOpcoesProps {
  isOpen: boolean;
  onClose: () => void;
  isOwner: boolean;
  itemId: number | string;
  tipoItem: 'usuario' | 'banda';
}

const ModalMenuOpcoes: React.FC<ModalMenuOpcoesProps> = ({
  isOpen,
  onClose,
  isOwner,
  itemId,
  tipoItem,
}) => {
  const navigate = useNavigate();
  const { state: authState, logout } = useContext(AuthContext);

  const handleLogout = async () => {
    try {
      await logout();
      onClose();
      navigate('/PrimeiraTela');
    } catch (error) {
      console.error('Falha ao fazer logout:', error);
    }
  };

  const handleEditProfile = () => {
    onClose();
    if (authState.id_usuario) {
      navigate('/UserEditPerfil');
    } else if (authState.id_banda) {
      navigate('/BandEditPerfil');
    } else {
      console.error('Nenhum ID de usuário ou banda encontrado');
    }
  };

  const handleDashboard = () => {
    onClose();
    if (authState.id_banda) {
      navigate(`/DashBoardBand/${authState.id_banda}`);
    } else {
      console.error('ID da banda não encontrado');
    }
  };

  const handleDenunciaEnviada = () => {
    onClose();
  };

  if (!isOpen) return null;

 return (
      <div style={styles.overlay}>
        <div style={styles.modal}>
          <button style={styles.closeButton} onClick={onClose}>Fechar</button>
          <h2 style={styles.title}>Opções do Perfil</h2>
          <div style={styles.optionsList}>
            {isOwner ? (
              <>
                <button style={styles.optionButton} onClick={handleEditProfile}>Editar Perfil</button>
                {authState.id_banda && (
                  <button style={styles.optionButton} onClick={handleDashboard}>Dashboard</button>
                )}
                <button style={styles.optionButton} onClick={handleLogout}>Sair</button>
              </>
            ) : (
              <BotaoDenunciaPerfil
                itemId={itemId}
                tipoItem={tipoItem}
                onDenunciaEnviada={handleDenunciaEnviada}
              />
            )}
          </div>
        </div>
      </div>
    );
  };
  
  const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 999, // Z-index mais baixo para overlay
    } as React.CSSProperties,
    modal: {
      backgroundColor: '#202020',
      padding: '20px',
      width: '60%',
      maxHeight: '80%',
      overflowY: 'auto',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      position: 'relative',
      zIndex: 1000, // Z-index aumentado para o modal
    } as React.CSSProperties,
    closeButton: {
      alignSelf: 'flex-end',
      backgroundColor: 'transparent',
      border: 'none',
      color: 'white',
      fontSize: '16px',
      cursor: 'pointer',
    } as React.CSSProperties,
    title: {
      color: 'white',
      fontSize: '24px',
      margin: '0 0 20px 0',
    } as React.CSSProperties,
    optionsList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    } as React.CSSProperties,
    optionButton: {
      backgroundColor: '#914100',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      padding: '10px',
      cursor: 'pointer',
      fontSize: '16px',
    } as React.CSSProperties,
  };
  
  export default ModalMenuOpcoes;
  