import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode, JwtPayload } from 'jwt-decode';

// Define uma interface personalizada que estende JwtPayload
interface CustomJwtPayload extends JwtPayload {
  exp?: number;
}

const RootRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      try {
        const decodedToken = jwtDecode<CustomJwtPayload>(token);
        const currentTime = Date.now() / 1000;
        
        // Verifica se 'exp' existe e é um número antes de comparar
        if (typeof decodedToken.exp === 'number' && decodedToken.exp > currentTime) {
          // Token válido, redireciona para uma página protegida
          navigate('/Palco');
        } else {
          // Token expirado ou sem 'exp', limpa o storage e redireciona para login
          localStorage.clear();
          navigate('/PrimeiraTela');
        }
      } catch (error) {
        // Token inválido, limpa o storage e redireciona para login
        console.error('Erro ao decodificar token:', error);
        localStorage.clear();
        navigate('/PrimeiraTela');
      }
    } else {
      // Sem token, redireciona para login
      navigate('/PrimeiraTela');
    }
  }, [navigate]);

  return null;
};

export default RootRedirect;