import React, { useState } from 'react';

interface ContratoUploadProps {
    onAccept: () => void;
    onClose: () => void;
}

const ContratoUpload: React.FC<ContratoUploadProps> = ({ onAccept, onClose }) => {
    const [aceito, setAceito] = useState(false);

    const handleAccept = () => {
        if (aceito) {
            onAccept();
        } else {
            alert("Por favor, aceite os termos do contrato antes de continuar.");
        }
    };

    return (
        <div style={estilos.modal}>
            <div style={estilos.modalContent}>
                <h2 style={estilos.modalTitle}>Contrato de Upload e Licenciamento de Conteúdo Musical</h2>
                <div style={estilos.contratoText}>
                    <h3>1. Introdução</h3>
                    <p>
                        Ao fazer o upload de conteúdo musical na plataforma Tribhus, você, doravante denominado "Artista", concorda com os termos e condições estabelecidos neste Contrato de Upload e Licenciamento de Conteúdo Musical. Este contrato estabelece as obrigações legais, as garantias e as licenças concedidas à Tribhus em relação ao conteúdo enviado. Se você não concorda com os termos aqui estabelecidos, não prossiga com o upload.
                    </p>

                    <h3>2. Definições</h3>
                    <p>
                        2.1. "Conteúdo Musical" refere-se a gravações de áudio, composições musicais, letras, artwork, metadados e qualquer material relacionado fornecido pelo Artista à Tribhus.
                    </p>
                    <p>
                        2.2. "Plataforma" refere-se ao site e aplicativo móvel Tribhus, uma plataforma de streaming com rede social dedicada ao rock.
                    </p>
                    <p>
                        2.3. "Lojas de Aplicativos" referem-se à Apple App Store e Google Play Store, através das quais o aplicativo Tribhus é distribuído aos usuários.
                    </p>

                    <h3>3. Processo de Avaliação e Curadoria</h3>
                    <p>
                        3.1. Ao fazer o upload da música, o Artista concorda que ela passará por um processo de avaliação realizado pela equipe de conteúdo da Tribhus para certificar que ela se enquadra em alguma vertente do rock.
                    </p>
                    <p>
                        3.2. A Tribhus se reserva o direito de aprovar ou rejeitar qualquer música, álbum ou conteúdo, com base em critérios de curadoria interna, que são definidos única e exclusivamente pelos administradores da Tribhus.
                    </p>
                    <p>
                        3.3. A decisão sobre a aprovação ou rejeição de conteúdo é final e irrevogável, e não está sujeita a recurso ou revisão. Esta avaliação pode resultar na aprovação parcial, completa ou na rejeição do conteúdo enviado.
                    </p>
                    <p>
                        3.4. O Artista reconhece que a curadoria musical é subjetiva e que os critérios de avaliação podem incluir, mas não se limitam a, estilo musical, qualidade da produção, originalidade, e adequação ao público-alvo da Tribhus. Em caso de rejeição, a Tribhus não é obrigada a fornecer justificativa detalhada para sua decisão.
                    </p>
                    <p>
                        3.5. O Artista concorda que a Tribhus não será responsável por qualquer dano, perda de oportunidades ou qualquer outro prejuízo resultante da rejeição de seu conteúdo, e que o envio de conteúdo não garante sua publicação na plataforma.
                    </p>

                    <h3>4. Declarações e Garantias do Artista</h3>
                    <p>
                        4.1. O Artista garante que é o titular exclusivo dos direitos autorais e de todos os direitos de propriedade intelectual sobre o conteúdo musical que está enviando, ou que possui todas as licenças, autorizações e permissões necessárias de terceiros para fazer o upload e disponibilizar o conteúdo na plataforma Tribhus.
                    </p>
                    <p>
                        4.2. O Artista garante que o conteúdo musical não infringe os direitos de terceiros, incluindo, mas não se limitando a, direitos autorais, direitos de marca registrada, direitos de imagem, ou quaisquer outros direitos de propriedade.
                    </p>
                    <p>
                        4.3. O Artista assume total responsabilidade por qualquer reclamação, dano ou responsabilidade que possa surgir em decorrência da violação de direitos de terceiros em relação ao conteúdo enviado.
                    </p>

                    <h3>5. Licença Concedida à Tribhus</h3>
                    <p>
                        5.1. O Artista concede à Tribhus uma licença mundial, não exclusiva, intransferível e isenta de royalties para usar, reproduzir, distribuir, exibir publicamente, e disponibilizar o conteúdo musical na plataforma Tribhus, exclusivamente para fins de streaming e exibição aos usuários.
                    </p>
                    <p>
                        5.2. Esta licença também permite à Tribhus utilizar trechos limitados do conteúdo musical para promover o trabalho do Artista dentro da plataforma e em materiais de marketing relacionados. Permite também que esse conteúdo musical possa integrar playlists criadas pela Tribhus ou por usuários sem a necessidade de permissões prévias, mas com as devidas atribuições ao autor, respeitando sempre os direitos morais do Artista.
                    </p>
                    <p>
                        5.3. A Tribhus não terá o direito de vender, modificar, ou criar obras derivadas do conteúdo musical sem a autorização expressa do Artista.
                    </p>

                    <h3>6. Distribuição do Aplicativo e Isenção de Responsabilidade</h3>
                    <p>
                        6.1. O Artista reconhece que o aplicativo Tribhus, através do qual o Conteúdo Musical é disponibilizado, é distribuído pelas Lojas de Aplicativos.
                    </p>
                    <p>
                        6.2. O Artista expressamente isenta as Lojas de Aplicativos de qualquer responsabilidade relacionada ao Conteúdo Musical, incluindo, mas não se limitando a, reclamações de violação de direitos autorais, direitos de propriedade intelectual ou quaisquer outros direitos de terceiros.
                    </p>
                    <p>
                        6.3. O Artista concorda em fornecer, mediante solicitação da Tribhus ou de qualquer Loja de Aplicativos, documentação adicional que comprove a titularidade dos direitos autorais ou as autorizações necessárias para a disponibilização do Conteúdo Musical na plataforma Tribhus.
                    </p>

                    <h3>7. Indenização e Remoção de Conteúdo</h3>
                    <p>
                        7.1. O Artista concorda em indenizar, defender e isentar a Tribhus e as Lojas de Aplicativos de qualquer responsabilidade, perda, dano, custo ou despesa, incluindo honorários advocatícios, que possam surgir em decorrência de qualquer violação das garantias fornecidas neste contrato, ou de qualquer reclamação de terceiros relacionada ao conteúdo enviado.
                    </p>
                    <p>
                        7.2. A Tribhus se reserva o direito de remover o conteúdo musical da plataforma a qualquer momento, caso seja identificado qualquer risco legal ou se o conteúdo violar estes termos.
                    </p>

                    <h3>8. Vigência e Rescisão</h3>
                    <p>
                        8.1. Este contrato permanecerá em vigor enquanto o conteúdo musical estiver disponível na plataforma Tribhus. O Artista pode rescindir este contrato a qualquer momento, solicitando a remoção do conteúdo da plataforma, o que resultará na revogação da licença concedida à Tribhus.
                    </p>
                    <p>
                        8.2. A Tribhus pode rescindir este contrato e remover o conteúdo musical da plataforma a qualquer momento, com ou sem aviso prévio. Em casos que não envolvam violação grave, a Tribhus envidará esforços razoáveis para fornecer um aviso prévio de 7 dias ao Artista.
                    </p>

                    <h3>9. Disposições Gerais</h3>
                    <p>
                        9.1. Este contrato é regido pelas leis do Brasil e qualquer disputa decorrente ou relacionada a este contrato será resolvida exclusivamente nos tribunais da comarca de Limeira, São Paulo.
                    </p>
                    <p>
                        9.2. Este contrato constitui o acordo integral entre o Artista e a Tribhus em relação ao upload e disponibilização de conteúdo musical, substituindo quaisquer acordos ou entendimentos anteriores referentes a esse assunto específico. Este contrato complementa, e não substitui, os Termos de Uso da Banda, que continuam a reger o relacionamento geral entre o Artista e a Tribhus.
                    </p>
                    <p>
                        9.3. Caso qualquer disposição deste contrato seja considerada inválida ou inexequível, as demais disposições continuarão em pleno vigor e efeito.
                    </p>
                    <p>
                        9.4. A Tribhus reserva-se o direito de modificar este contrato a qualquer momento. As alterações entrarão em vigor após a notificação ao Artista, que poderá ser feita por e-mail, notificação dentro da plataforma, ou outro meio de comunicação adequado. A continuidade do uso da plataforma após a notificação constituirá aceitação das modificações.
                    </p>
                    <p>
                        9.5. O Artista não pode ceder ou transferir este contrato sem o consentimento prévio por escrito da Tribhus.
                    </p>

                    <h3>10. Aceitação</h3>
                    <p>
                        Ao aceitar este contrato, você concorda com os termos aqui estabelecidos e reconhece que esta aceitação constitui uma assinatura eletrônica vinculativa.
                    </p>
                </div>
                <div style={estilos.checkboxContainer}>
                    <input
                        type="checkbox"
                        id="aceitoContrato"
                        checked={aceito}
                        onChange={(e) => setAceito(e.target.checked)}
                    />
                    <label htmlFor="aceitoContrato">Li e aceito os termos do contrato</label>
                </div>
                <div style={estilos.buttonContainer}>
                    <button onClick={handleAccept} style={estilos.acceptButton}>
                        Aceitar e Continuar
                    </button>
                    <button onClick={onClose} style={estilos.closeButton}>
                        Fechar
                    </button>
                </div>
            </div>
        </div>
    );
};

const estilos = {
    modal: {
        position: 'fixed' as 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modalContent: {
        backgroundColor: '#151922',
        padding: '20px',
        borderRadius: '5px',
        maxWidth: '80%',
        maxHeight: '80%',
        overflow: 'auto',
        color: '#ffffff',
    },
    modalTitle: {
        marginBottom: '15px',
        color: '#ffffff',
    },
    contratoText: {
        marginBottom: '20px',
        maxHeight: '400px',
        overflowY: 'auto' as 'auto',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
    },
    checkboxContainer: {
        marginBottom: '15px',
        color: '#ffffff',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    acceptButton: {
        padding: '10px 20px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    closeButton: {
        padding: '10px 20px',
        backgroundColor: '#f44336',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};

export default ContratoUpload;
