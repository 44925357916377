import React, { useEffect, useRef, useState } from 'react';
import EventDetailsModal from './EventDetailsModal';
import AddEventoModal from './AddEventoModal';

interface EventProps {
  id_banda: number;
  token: string;
  isOwner: boolean; 
}

interface Evento {
  id_evento: number;
  nome_evento: string;
  data_evento: string;
  local_evento: string;
  cidade: string;
  url_imagem_evento: string;
}

const Eventos: React.FC<EventProps> = ({ id_banda, token, isOwner }) => {
  const [events, setEvents] = useState<Evento[]>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<number | null>(null);
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);

  const handleEventClick = (evento: Evento) => {
    setSelectedEventId(evento.id_evento);
    setModalOpen(true);
  };

  const fetchEvents = async () => {
    if (!id_banda || !token) {
      console.log('Token ou id_banda não disponíveis - execução interrompida');
      return;
    }

    try {
      const response = await fetch(`https://tribhus.shop:5000/eventos/events/${id_banda}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (Array.isArray(data)) {
        setEvents(data);
      } else {
        console.error("Resposta inesperada da API:", data);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [id_banda, token]);

  const handleEventDeleted = () => {
    fetchEvents();
    setModalOpen(false);
  };

  const handleScroll = (direction: string) => {
    if (direction === "left") {
      listRef.current?.scrollBy({
        top: 0,
        left: -window.innerWidth / 2,
        behavior: "smooth"
      });
    } else {
      listRef.current?.scrollBy({
        top: 0,
        left: window.innerWidth / 2,
        behavior: "smooth"
      });
    }
  };

  const checkArrowsVisibility = () => {
    if (listRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener('scroll', checkArrowsVisibility);
    }
    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener('scroll', checkArrowsVisibility);
      }
    };
  }, []);
 
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h2 style={styles.sectionTitle}>Eventos</h2>
      </div>
      <div style={styles.listContainer}>
        {showLeftArrow && (
          <div style={{ ...styles.arrow, ...styles.leftArrow }} onClick={() => handleScroll("left")}>
            &#9664;
          </div>
        )}
        <div style={styles.list} ref={listRef}>
          {events.map((evento) => (
            <div key={evento.id_evento} style={styles.card} onClick={() => handleEventClick(evento)}>
              <div style={styles.imageContainer}>
                <img src={evento.url_imagem_evento} alt={evento.nome_evento} style={styles.image} />
              </div>
              <p style={styles.eventName}>{evento.nome_evento}</p>
              <p style={styles.eventLocation}>{evento.local_evento}</p>
              <p style={styles.eventDate}>{new Date(evento.data_evento).toLocaleDateString()}</p>
            </div>
          ))}
          {isOwner && (
            <div style={styles.addEventCard} onClick={() => setIsAddEventModalOpen(true)}>
              <span style={styles.addEventText}>+ Adicionar Evento</span>
            </div>
          )}
        </div>
        {showRightArrow && (
          <div style={{ ...styles.arrow, ...styles.rightArrow }} onClick={() => handleScroll("right")}>
            &#9654;
          </div>
        )}
      </div>
      <EventDetailsModal 
  isOpen={modalOpen}
  onClose={() => setModalOpen(false)}
  eventId={selectedEventId}
  token={token}
  onEventDeleted={handleEventDeleted}
  isOwner={isOwner} 
/>
      {isOwner && (
        <AddEventoModal
          isOpen={isAddEventModalOpen}
          onClose={() => setIsAddEventModalOpen(false)}
          onEventAdded={() => {
            fetchEvents();
            setIsAddEventModalOpen(false);
          }}
          id_banda={id_banda}
          token={token}
        />
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    overflow: 'hidden',
    position: 'relative' as const,
    maxWidth: '100%',
    margin: '10px auto 0',
    marginBottom: '60px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    marginBottom: '10px',
  },
  sectionTitle: {
    fontSize: '20px',
    fontWeight: 'bold' as const,
    color: '#FF6600',
    margin: 0,
  },
  listContainer: {
    position: 'relative' as const,
  },
  list: {
    display: 'flex',
    flexDirection: 'row' as const,
    overflowX: 'scroll' as const,
    scrollSnapType: 'x mandatory' as const,
    scrollBehavior: 'smooth' as const,
    scrollbarWidth: 'none' as const,
    msOverflowStyle: 'none' as const,
    padding: '0 20px',
  },
  card: {
    flex: '0 0 auto',
    scrollSnapAlign: 'start' as const,
    margin: '0 5px',
    padding: '20px',
    borderRadius: '20px',
    position: 'relative' as const,
    width: '150px',
    backgroundColor: '#202020',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  imageContainer: {
    width: '180px',
    height: '180px',
    overflow: 'hidden',
    borderRadius: '10px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover' as const,
  },
  eventName: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold' as const,
    margin: '10px 0 5px',
    textAlign: 'center' as const,
  },
  eventLocation: {
    color: 'white',
    fontSize: '12px',
    textAlign: 'center' as const,
  },
  eventDate: {
    color: 'white',
    fontSize: '12px',
    textAlign: 'center' as const,
  },
  addEventCard: {
    flex: '0 0 auto',
    scrollSnapAlign: 'start' as const,
    margin: '0 5px',
    padding: '20px',
    borderRadius: '20px',
    position: 'relative' as const,
    width: '150px',
    backgroundColor: '#202020',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  addEventText: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold' as const,
    textAlign: 'center' as const,
  },
  arrow: {
    position: 'absolute' as const,
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    zIndex: 10,
    transition: 'opacity 0.3s',
    opacity: 0.5,
  },
  leftArrow: {
    left: '10px',
  },
  rightArrow: {
    right: '10px',
  },
};

export default Eventos;