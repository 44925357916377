import React, { useContext, useState, useEffect, useRef } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { io, Socket } from 'socket.io-client';

interface ChatMessage {
    id: number;
    sender: string;
    mensagem: string; 
    enviadoPorUsuarioLogado?: boolean;
    lida?: boolean;
}

interface Conversa {
    id_conversa: number;
    messages: Array<any>;
} 

interface ChatBoxProps {
    onClose?: () => void; 
    idUsuarioRecebe?: number;
    idBandaRecebe?: number;
    idConversa?: number; 
}

interface ReceivedMessageData {
    id_mensagem: number;
    id_conversa: number;
    id_usuario_envia: number;
    mensagem: string;
    data_hora: string;
    lida: boolean;
}

const ChatBox: React.FC<ChatBoxProps> = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { otherParticipantName } = location.state;
    const { idUsuarioRecebe, idBandaRecebe, idConversa } = location.state || {};
    const [socket, setSocket] = useState<Socket | null>(null);
    const [message, setMessage] = useState('');
    const [chat, setChat] = useState<ChatMessage[]>([]);
    const { state: authState } = useContext(AuthContext);
    const scrollViewRef = useRef<HTMLDivElement>(null);

    const onClose = () => navigate(-1);
  
    useEffect(() => {
        console.log("Tentando criar conexão do socket");
        const newSocket = io('https://tribhus.shop:5000', {
            query: { token: authState.token }
        });
        
        newSocket.on('connect', () => {
            console.log('Socket conectado com sucesso');
        });
    
        newSocket.on('connect_error', (error) => {
            console.error('Erro na conexão do socket:', error);
        });
    
        setSocket(newSocket);
    
        return () => {
            newSocket.close();
        };
    }, [authState.token]);
    
    useEffect(() => {
        if (!socket) return;

        const handleMessageReceive = (data: ReceivedMessageData) => {
            console.log('Mensagem recebida:', data);
        
            const enviadoPorUsuarioLogado = data.id_usuario_envia === (authState.id_usuario || authState.id_banda);
            const newChatMessage: ChatMessage = {
                id: data.id_mensagem,
                sender: enviadoPorUsuarioLogado ? 'you' : 'other',
                mensagem: data.mensagem,
                enviadoPorUsuarioLogado: enviadoPorUsuarioLogado,
                lida: data.lida
            };
        
            setChat((oldChat) => [...oldChat, newChatMessage]);
        
            if (!enviadoPorUsuarioLogado && idConversa !== undefined) {
                markMessageAsRead(data.id_mensagem, idConversa);
            }
        };        
                  
        socket.on('receiveMessage', handleMessageReceive);
    
        return () => {
            socket.off('receiveMessage', handleMessageReceive);
        };
    }, [socket, idConversa, authState.id_usuario, authState.id_banda]);    
    
    const sendMessage = (message: string) => {
        if (message !== '' && socket) {
            const tempId = Date.now();
            const newMessage = {
                id: tempId,
                sender: 'you',
                mensagem: message,
            };
    
            setChat(chat => [...chat, newMessage]);
            scrollToBottom();

            let sendData = {
                token: authState.token,
                mensagem: message,
                idConversa,
                entityType: authState.id_usuario ? 'usuario' : 'banda',
                id_usuario_recebe: idUsuarioRecebe,
                id_banda_recebe: idBandaRecebe
            };
    
            if (!idConversa) {
                if (idUsuarioRecebe !== undefined && idBandaRecebe === undefined) {
                    sendData.entityType = 'usuario';
                    sendData.id_usuario_recebe = idUsuarioRecebe;
                } else if (idBandaRecebe !== undefined && idUsuarioRecebe === undefined) {
                    sendData.entityType = 'banda';
                    sendData.id_banda_recebe = idBandaRecebe;
                } else {
                    console.error('No authenticated entity found or multiple entities defined.');
                    return;
                }
            }
    
            socket.emit('sendMessage', sendData);
            setMessage('');
        }
    };    

    useEffect(() => {
        console.log("ChatBox: idConversa atualizado para:", idConversa);
        if (idConversa) {
            console.log("Buscando mensagens para a conversa ID:", idConversa);
            fetch(`https://tribhus.shop:5000/mensagens/messages/${idConversa}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authState.token}`
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Falha ao buscar mensagens');
                }
                return response.json();
            })
            .then((data: Array<Conversa | null>) => {
                console.log("Dados recebidos em chatbox:", data); 
                const validConversations = data.filter((convo): convo is Conversa => convo !== null);
                const conversationData = validConversations.find(convo => convo.id_conversa === idConversa);
    
                if (conversationData && Array.isArray(conversationData.messages)) {
                    const messages = conversationData.messages.map(msg => ({
                        id: msg.id_mensagem,
                        mensagem: msg.mensagem,
                        enviadoPorUsuarioLogado: msg.enviado_por_usuario_logado,
                        sender: msg.enviado_por_usuario_logado ? 'you' : 'other'
                    }));
                    setChat(messages);
                    scrollToBottom();
                } else {
                    console.error("Formato de resposta inesperado:", data);
                }
            })
            .catch(error => {
                console.error("Erro ao buscar mensagens:", error);
            });
        }
    }, [idConversa, authState.token]);    
        
    const markMessageAsRead = (messageId: number, conversaId: number) => {
        if (conversaId !== undefined) {
            socket?.emit('messageRead', { messageId, conversaId });
    
            setChat(chat => chat.map(msg => 
                msg.id === messageId ? { ...msg, lida: true } : msg
            ));
        }
    };

    useEffect(() => {
        chat.forEach((msg) => {
            if (msg.sender !== 'you' && !msg.lida && idConversa !== undefined) {
                markMessageAsRead(msg.id, idConversa);
            }
        });
    }, [chat, idConversa]);
  
    const scrollToBottom = () => {
        scrollViewRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        if (chat.length) scrollToBottom();
    }, [chat.length]);

   
    return (
        <div style={styles.chatBox}>
            <div style={styles.chatHeader}>
                <button onClick={onClose} style={styles.backButton}>
                    <img
                        src={require('../assets/voltar.png')}
                        alt="Voltar"
                        style={styles.backIcon}
                    />
                </button>
                <h2 style={styles.chatTitle}>{otherParticipantName}</h2> {/* Exibe o nome no cabeçalho */}
            </div>
            <div style={styles.chatDisplay}>
                {chat.map((msg, index) => (
                    <div
                        key={index}
                        style={{
                            ...styles.messageText,
                            ...(msg.sender === 'you' ? styles.sent : styles.received)
                        }}
                        onClick={() => idConversa && markMessageAsRead(msg.id, idConversa)}
                    >
                        <p style={styles.messageContent}>{msg.mensagem}</p>
                    </div>
                ))}
                <div ref={scrollViewRef} />
            </div>
            <div style={styles.chatInputArea}>
                <input
                    style={styles.input}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Digite sua mensagem"
                />
                <button onClick={() => sendMessage(message)}>
                    <img
                        src={require('../assets/enviar.png')}
                        alt="Enviar"
                        style={{ width: 20, height: 20 }}
                    />
                </button>
            </div>
        </div>
    );
};

    const styles: { [key: string]: React.CSSProperties } = {
        chatBox: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            backgroundColor: '#151922',
            justifyContent: 'center',
            alignItems: 'center',
        },
        chatHeader: {
            backgroundColor: '#914100',
            padding: '5px',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: '600px',
            alignItems: 'center',  // Centraliza verticalmente o conteúdo do cabeçalho
        },
        backButton: {
            backgroundColor: 'transparent',  // Remove a cor de fundo
            border: 'none',  // Remove a borda
            padding: 0,  // Remove o padding para que o ícone fique no tamanho original
            cursor: 'pointer',  // Muda o cursor para pointer ao passar o mouse
        },
        backIcon: {
            width: '24px',
            height: '24px',
        },
        chatTitle: {
            color: '#faf2eb',
            fontSize: '18px',
            fontWeight: 'bold',
            margin: '0 auto',  // Centraliza o título no cabeçalho
        },
        chatDisplay: {
            flex: 1,
            overflowY: 'auto',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            maxWidth: '600px',
        },
        messageText: {
            padding: '10px',
            margin: '5px 0',
            maxWidth: '70%',
            borderRadius: '10px',
            wordWrap: 'break-word',
            display: 'block',
        },
        sent: {
            backgroundColor: '#ccaf97',
            alignSelf: 'flex-end',
            marginLeft: 'auto',
            textAlign: 'right',
        },
        received: {
            backgroundColor: 'white',
            alignSelf: 'flex-start',
            marginRight: 'auto',
            textAlign: 'left',
        },
        messageContent: {
            fontSize: '16px',
        },
        chatInputArea: {
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#151922',
            width: '100%',
            maxWidth: '600px',
        },
        input: {
            flex: 1,
            padding: '10px',
            margin: '0 10px',
            borderRadius: '5px',
            border: '1px solid #914100',
            backgroundColor: '#2a2d35',
            color: '#faf2eb',
        },
    };
    
    export default ChatBox;
    