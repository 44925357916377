import React, { useEffect, useRef, useState } from 'react';
import Modal from './ModalAlbum';

interface Album {
  id_album: number;
  id_banda: number;
  nome_album: string;
  ano_lancamento: number;
  url_capa_album: string;
}

interface AlbumProps {
  id_banda: number;
  token: string;
}
const Album: React.FC<AlbumProps> = ({ id_banda, token }) => {
  const [selectedAlbum, setSelectedAlbum] = useState<Album | null>(null);
  const [albums, setAlbums] = useState<Album[]>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const handleAlbumClick = (album: Album) => {
    setSelectedAlbum(album);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
         return; // Encerra a execução se não houver token
      }
  
 
      try {
        const response = await fetch(`https://tribhus.shop:5000/bands/albuns/${id_banda}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        console.log('Resposta recebida do servidor');
        console.log('Status da resposta:', response.status);
        console.log('Headers da resposta:', response.headers);
  
        const data = await response.json();
  
        console.log('Dados recebidos do servidor:', data);
  
        if (data.error) {
          console.error("Error from server:", data.error);
        } else if (Array.isArray(data)) {
          console.log(`Número de álbuns recebidos: ${data.length}`);
          console.log('Primeiro álbum (se existir):', data[0]);
          setAlbums(data);
        } else {
          console.error("Dados recebidos não são um array:", data);
        }
      } catch (error) {
        console.error("Error fetching albums:", error);
      }
    };
  
    fetchData();
  }, [id_banda, token]);

  const handleScroll = (direction: string) => {
    if (direction === "left") {
      listRef.current?.scrollBy({
        top: 0,
        left: -window.innerWidth / 2,
        behavior: "smooth"
      });
    } else {
      listRef.current?.scrollBy({
        top: 0,
        left: window.innerWidth / 2,
        behavior: "smooth"
      });
    }
  };

  const checkArrowsVisibility = () => {
    if (listRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener('scroll', checkArrowsVisibility);
    }
    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener('scroll', checkArrowsVisibility);
      }
    };
  }, []);

  return (
    <div>
      <p style={styles.sectionTitle}>Álbum</p> {/* Adiciona o título aqui */}
      <div style={styles.container}>
        {showLeftArrow && (
          <div style={{ ...styles.arrow, ...styles.leftArrow }} onClick={() => handleScroll("left")}>
            &#9664; {/* Código HTML para uma seta esquerda */}
          </div>
        )}
        <div style={styles.list} ref={listRef}>
          {albums.map((item: Album) => (
            <div key={item.id_album} style={styles.card} onClick={() => handleAlbumClick(item)}>
              <img src={item.url_capa_album} style={styles.image} alt={item.nome_album} />
              <p style={styles.title}>{item.nome_album}</p>
              <p style={styles.year}>{item.ano_lancamento}</p>
            </div>
          ))}
        </div>
        {showRightArrow && (
          <div style={{ ...styles.arrow, ...styles.rightArrow }} onClick={() => handleScroll("right")}>
            &#9654; {/* Código HTML para uma seta direita */}
          </div>
        )}
        {selectedAlbum && <Modal isOpen={true} album={selectedAlbum} onClose={() => setSelectedAlbum(null)} />}
      </div>
    </div>
  );
};  

const styles = {
  container: {} as React.CSSProperties,
  list: {} as React.CSSProperties,
  card: {} as React.CSSProperties,
  image: {} as React.CSSProperties,
  title: {} as React.CSSProperties,
  year: {} as React.CSSProperties,
  arrow: {} as React.CSSProperties,
  leftArrow: {} as React.CSSProperties,
  rightArrow: {} as React.CSSProperties,
  sectionTitle: {} as React.CSSProperties,
};

styles.container = {
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden',
  position: 'relative',
  maxWidth: '100%',
  margin: '0 auto',
  alignItems: 'center',
};
styles.sectionTitle = {
  fontSize: '20px',
  fontWeight: 'bold' as const,
  color: '#FF6600',
  margin: 0,
  marginTop: '40px'
};
styles.list = {
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'scroll',
  scrollSnapType: 'x mandatory',
  scrollBehavior: 'smooth',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  padding: '0 20px',
};

styles.card = {
  flex: '0 0 auto',
  scrollSnapAlign: 'start',
  margin: 5,
  padding: 10,
  borderRadius: 10,
  position: 'relative',
  width: '200px',
  backgroundColor: '#202020', // altere para a cor do seu tema
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

styles.image = {
  width: '100%',
  height: 'auto',
  borderRadius: '10px',
};

styles.title = {
  color: 'white',
  fontSize: 16,
  fontWeight: 'bold',
  margin: '10px 0',
};

styles.year = {
  color: 'white',
  fontSize: 12,
};

styles.arrow = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  padding: '10px',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '50%',
  color: 'white',
  cursor: 'pointer',
  zIndex: 10,
  transition: 'opacity 0.3s',
  opacity: 0.5,
};

styles.leftArrow = {
  left: '10px',
};

styles.rightArrow = {
  right: '10px',
};

export default Album;
